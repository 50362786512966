import React from "react";
import axios from "axios";

import HomePresentational from "../Presentationals/HomePresentational"

class HomeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apps: [],
      isOpenModal: false,
      title: "",
      description: "",
    }
  }

  componentDidMount(){
    this.fetchAllApps();
  }

  toggleModal = () => {
    this.setState({ isOpenModal: !this.state.isOpenModal })
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  goToApp = (id) => {
    this.props.history.push(`/app/${id}`)
  }

  fetchAllApps = () => {
    axios
    .get('/app')
    .then(resp => {
      this.setState({
        apps: resp.data.apps
      })
    })
    .catch(err => console.error(err.response))
  }

  addNewChapter = () => {
    axios
      .post('/app', {
        title: this.state.title,
        description: this.state.description
      })
      .then(resp => {
        this.fetchAllApps();
        this.setState({
          isOpenModal:false
        })
      })
      .catch(err => {
        console.error(err.response)
      })

  }

  render(){
    return(
      <HomePresentational 
        addNewChapter={this.addNewChapter}
        goToApp={this.goToApp}
        onChange={this.onChange}
        toggleModal={this.toggleModal}
        isOpenModal={this.state.isOpenModal}
        logout={this.logout}
        apps={this.state.apps}
        history={this.props.history}
      />
    )
  }
}

export default HomeContainer;