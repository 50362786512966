import React from "react";
import { Col, Button, Form, FormGroup} from 'reactstrap';

import * as Styles from "./NotesBuilderStyled"
import * as Formed from "../../../components/StyledItems/Form"
import ButtonWithState from "../../../components/ButtonWithState"

const ConversationBuilder = props => {
  return (
    <Styles.NotesWrapper>
            <Formed.ParticleHeading>Conversation</Formed.ParticleHeading>

            <Form>

            <Formed.TitleInput 
              type="text" 
              name="title" 
              value={props.title}
              onChange={props.onChangeNotes}
              placeholder="enter title" />

            {props.conversation.map((option, index) => {
              return (
                <FormGroup row key={`option-${index}`}>
                  <Col sm={4}>
                    <FormGroup>
                      <Formed.OrdinaryInput 
                        type="text" 
                        name={`name`} 
                        value={option.name}
                        onChange={(e) => props.onChangeConversation(e, index)}
                        placeholder="enter name" />
                    </FormGroup>
                  </Col>
                  <Col sm={8}>
                  <FormGroup>
                      <Formed.OrdinaryInput 
                        type="text" 
                        name={`sentence`} 
                        value={option.sentence}
                        onChange={(e) => props.onChangeConversation(e, index)}
                        placeholder="enter sentence" />
                    </FormGroup>
                    <FormGroup>
                      <Formed.OrdinaryInput 
                        type="text" 
                        name={`translation`} 
                        value={option.translation}
                        onChange={(e) => props.onChangeConversation(e, index)}
                        placeholder="enter translation" />
                    </FormGroup>
                  </Col>
                </FormGroup>
              )
            })}
          <Button onClick={props.addConversation}>Add Conversation</Button>

      </Form>
      <Styles.BottomButtonWrapper>
        <FormGroup style={{paddingRight: "15px"}}>
          <Button color="danger" onClick={props.deleteNote}>delete</Button>
        </FormGroup>
        <FormGroup>
          {/* <Button color="primary" onClick={props.saveNotes}>save</Button> */}
          <ButtonWithState 
            color="primary" 
            text="save"
            successText="saved"
            failureText="failed"
            state={props.networkState}
            onClick={props.saveNotes}/>
        </FormGroup>
      </Styles.BottomButtonWrapper>
      {/* <div>{props.message}</div> */}
    </Styles.NotesWrapper>
  )
}

export default ConversationBuilder