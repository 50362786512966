import styled from "styled-components";

export const Heading = styled.h1`
  font-family: 'Ubuntu', serif;
  font-weight: 600;
  margin: 32px 0px;
  font-family: 'Ubuntu', sans-serif;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* font-family: 'Ubuntu', serif; */
`

export const InputField = styled.input`
  /* font-family: 'Ubuntu', serif; */
  font-weight: 600;
  border: none;
  border-bottom: 2px solid #a2a2a2;
  width: 100%;
  max-width: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  margin: 30px 0px;
  font-family: 'Ubuntu', sans-serif;
`

export const ButtonWrapper = styled.div`
  max-width: 320px;
`

export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const FormWrapper = styled.div`
  display: flex;
  width: 60%;
  justify-content: center;
  align-items: center;
  /* padding: 10%; */
`

export const FormContent = styled.div`
  /* width: 50%; */
  width: 300px;
`

export const LeftWrapper = styled.div`
  width: 40%;
  height: 100vh;
  @media (max-width: 768px) {
    display: none;
  }
`

export const LoginText = styled.div`
  /* font-family: 'Ubuntu', serif; */
  font-weight: 600;
  margin-top: 15px;
  font-family: 'Ubuntu', sans-serif;
`

export const LoginTextClickable = styled.span`
  text-decoration: underline;
  color: grey;
  cursor: pointer;
`;

export const ForgotPasswordText = styled.div`
  font-family: 'Ubuntu', sans-serif;
  font-weight: 300;
  padding-bottom: 10px;
`