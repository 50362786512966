import React from "react";

import * as Styles from "./Sidebar.style"

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    return (
      <Styles.SidebarWrapper>
        <Styles.Heading>
          make<br/>them<br/>stay
        </Styles.Heading>
        {this.props.itemTypes.map((item, index) => {
          return (
            <Styles.Item 
              key={item._id} 
              onClick={() => this.props.onChangeItemType(item._id)}
              selected={item._id === this.props.selectedTypeId}
            >
              {item.title}
            </Styles.Item>
          )
        })}
      </Styles.SidebarWrapper>
    )
  }
}

export default Sidebar;