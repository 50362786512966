import React from "react";
import AddNewModal from "./AddNewModal"
import StudentModal from "./StudentModal"
import Segment from "./Segment"
import * as Styles from "./ChapterBarStyled";

const ChapterBarPresentational = props => {
  return (
    <Styles.BarWrapper>
      {/* <Styles.Heading>
        {props.app.title}
      </Styles.Heading> */}
      <Styles.SegmentListWrapper>
        {props.segments.map((segment, index) => {
          return (
            <Segment 
              key={segment._id}
              segment={segment}
              index={index}
              deleteSegment={props.deleteSegment}
              selectChapter={props.selectChapter}
              toggleAddLessonModal={props.toggleAddLessonModal}
              selectedChapter={props.selectedChapter}
              toggleSegmentPublish={props.toggleSegmentPublish}
              />
          )
        })}
      </Styles.SegmentListWrapper>
      <Styles.Button onClick={() => props.toggleAddSegmentModal()}>
        + Add new collection
      </Styles.Button>
      {/* <Styles.Button onClick={() => props.toggleAddStudentModal()}>
        + Edit students
      </Styles.Button> */}

      <AddNewModal 
        modalHeading={"Add new chapter"}
        showModal={props.showAddLessonModal}
        title={props.title}
        type={props.type}
        modalCategory="chapter"
        formOnChange={props.formOnChange}
        description={props.description}
        submitNewChapter={props.submitNewChapter}
        error={props.error}
        showType={false} // this is for games, need to add type. Right now due to the absence of games, we have removed this. This needs to be uncommented and looked into
        submitChapterState={props.submitChapterState}
        toggleAddLessonModal={props.toggleAddLessonModal} />

      <AddNewModal
        modalHeading={"Add new segment"}
        showModal={props.showAddSegmentModal}
        title={props.title}
        type={props.type}
        modalCategory="segment"
        formOnChange={props.formOnChange}
        description={props.description}
        submitNewChapter={props.submitNewSegment}
        error={props.error}
        showType={false} // this is for games, need to add type. Right now due to the absence of games, we have removed this. This needs to be uncommented and looked into
        submitChapterState={props.submitSegmentState}
        toggleAddLessonModal={props.toggleAddSegmentModal} />

      {props.showAddStudentModal && (
        <StudentModal 
          showAddStudentModal={props.showAddStudentModal}
          app={props.app}
          toggleAddStudentModal={props.toggleAddStudentModal}
        />
      )}
    </Styles.BarWrapper>
  )
}

export default ChapterBarPresentational;