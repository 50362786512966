import React from 'react';
import { Button, Form, FormGroup } from 'reactstrap';

import * as Styles from "./NotesBuilderStyled"
import * as Formed from "../../../components/StyledItems/Form"
import ButtonWithState from "../../../components/ButtonWithState"

const NotesBuilderPresentational = props => {
  return (
    <Styles.NotesWrapper>
      <Formed.ParticleHeading>Meaning Card</Formed.ParticleHeading>
      <Form>
        <Formed.OrdinaryInput
          type="text" 
          name="question" 
          id="question" 
          value={props.question}
          onChange={props.onChangeNotes}
          placeholder="- Enter word in from language" />
        <Formed.OrdinaryInput
          type="text" 
          name="answer" 
          id="answer" 
          value={props.answer}
          onChange={props.onChangeNotes}
          placeholder="- Enter word in to language" />
      </Form>
      <Styles.BottomButtonWrapper>
        <FormGroup style={{paddingRight: "15px"}}>
          <Button color="danger" onClick={props.deleteNote}>delete</Button>
        </FormGroup>
        <FormGroup>
          <ButtonWithState 
            color="primary" 
            text="save"
            successText="saved"
            failureText="failed"
            state={props.networkState}
            onClick={props.saveNotes}/>
        </FormGroup>
        {/* <div>{props.message}</div> */}
      </Styles.BottomButtonWrapper>
    </Styles.NotesWrapper>
  )
}

export default NotesBuilderPresentational;