import styled from "styled-components"

export const EmailAnchor = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #007bff !important;
`;

export const DownSpan = styled.span`
  padding-right: 5px;
  display: flex;
`