import React from 'react';
import { 
  Modal, 
  Button, 
  Popconfirm, 
  Col,
  Row,
  Table } from 'antd';
import axios from "axios";
import { DeleteOutlined } from "@ant-design/icons"

import * as Formed from "../../../components/StyledItems/Form"

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

class StudentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      students: [],
      studentEmail:'',
      error: '',
      loading: false
    }
    this.columns = [
      {
        title: 'email',
        dataIndex: 'studentEmail',
        editable: true
      },
      {
        title: 'operation',
        dataIndex: 'operation',
        render: (text, record) => {
          return (
            <Popconfirm title="Sure to delete?" onConfirm={() => this.deleteStudent(record._id)}>
              <DeleteOutlined />
            </Popconfirm>
          )
        }
      }
    ]
  }

  componentDidMount() {
    this.fetchAllStudents();
  }

  fetchAllStudents = () => {
    axios.get(`/student/all-students?appId=${this.props.app._id}`)
      .then(resp => {
        this.setState({
          students: resp.data.students
        })
      })
      .catch(err => {
        console.error(err.response)
      })
  }

  addStudent = () => {
    if(!this.state.studentEmail){
      this.setState({
        error: "enter student email"
      })
      return;
    }
    let validated = validateEmail(this.state.studentEmail)
    if(!validated){
      this.setState({
        error: 'Invalid email'
      })
      return;
    }
    this.setState({
      loading: true
    })
    axios.post(`/student`, {
      appId: this.props.app._id,
      studentEmail: this.state.studentEmail
    })
    .then(resp => {
      this.fetchAllStudents();
      this.setState({
        studentEmail: "",
        loading: false
      })
    })
    .catch(err => {
      console.error(err.response)
    })
  }

  showModal = () => {
    // setIsModalVisible(true);
    this.props.toggleAddStudentModal()
  };

  handleOk = () => {
    // setIsModalVisible(false);
    this.props.toggleAddStudentModal()
  };

  handleCancel = () => {
    // setIsModalVisible(false);
    this.props.toggleAddStudentModal()
  };

  deleteStudent = (studentId) => {
    axios.delete(`/student?id=${studentId}`)
    .then(resp => {
      this.fetchAllStudents();
    })
    .catch(err => {
      console.error(err.response)
    })
  }



  render(){
    return (
      <>
        <Modal 
          title="Students" 
          bodyStyle={{
            maxHeight:'70vh',
            display: 'flex'
          }}
          visible={this.props.showAddStudentModal} 
          onOk={this.handleOk}
          footer={[
            <Button key="submit" type="primary" onClick={this.handleOk}>
              Done
            </Button>,
          ]}
          onCancel={this.handleCancel}>
          <div style={{display: 'flex', flexDirection: 'column', flexShrink: 1, width: '100%'}}>
            <div style={{overflow: 'auto'}}>
              <Table
                rowClassName={() => 'editable-row'}
                bordered
                rowKey={(t) => {
                  return t._id
                }}
                pagination={false}
                dataSource={this.state.students}
                columns={this.columns}
              />
            </div>
            <div style={{marginTop: '20px'}}>
              <Row>
                <Col span={20}>
                  <Formed.OrdinaryInput
                    type="text"
                    name='student-email'
                    id='student-email'
                    onChange={e => {
                      this.setState({studentEmail: e.target.value, error: ''})
                    }}
                    value={this.state.studentEmail}
                    placeholder="enter student email"
                  />
                </Col>
                <Col span={2}>
                  <Button onClick={this.addStudent} loading={this.state.loading}>
                    Add
                  </Button>
                </Col>
              </Row>
              <div style={{color: 'red'}}>{this.state.error}</div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
};

export default StudentModal