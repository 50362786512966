import React from 'react'
import { Divider, Checkbox, Row, Col, Tooltip, Popover, Popconfirm } from 'antd';
import { DeleteOutlined } from "@ant-design/icons"
import * as Styles from "./ChapterBarStyled";
import axios from "axios"


class Segment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      segmentTitle : "",
      networkState: "IDLE"
    }
    this.timer = null;
  }

  componentDidMount() {
    this.setState({
      segmentTitle: this.props.segment.title
    })
  }

  titleOnChange = (e) => {
    this.setState({
      segmentTitle: e.target.value,
      networkState: "PENDING"
    })

    if(this.timer){
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => { this.patchSegmentDetails()}, 1000)

  }

  patchSegmentDetails = () => {
    axios
      .patch(`/segment/${this.props.segment._id}`, {
        title: this.state.segmentTitle
      })
      .then(resp => {
        this.setState({
          networkState: "COMPLETED"
        })
      })
      .catch(err => {
        this.setState({
          networkState: "ERROR"
        })
      })
  }

  render() {
    let { props } = this;
    let { segment } = props;
    return (
      <Styles.IndividualSegmentWrapper 
        key={segment._id}
        >
        <Styles.SegmentTitleWrapper>
        <Row style={{width: '100%'}}>
          <Col span={18}>
            <Styles.IndividualSegmentTitle 
              type="text" 
              name="title"
              networkState={this.state.networkState}
              value={this.state.segmentTitle}
              onChange={this.titleOnChange}
              placeholder="enter segment title" 
            />
          </Col>
          <Col span={2}>
            <Popover title="Delete segment" content={() => (<div>This will delete the segment along with <br/> the chapters in the segment. So be careful</div>)}>
            <Popconfirm
              title={`Are you sure you want to delete this chapter permanently?`}
              onConfirm={() => props.deleteSegment(segment._id)}
              onCancel={() => null}
              okText="Yes"
              cancelText="No"
              placement="topRight"
            >
              <DeleteOutlined />  
            </Popconfirm>
            </Popover>
          </Col>
          <Col span={2}>
            <Tooltip title="selecting this will publish this segment to the app">
              <Checkbox 
                onChange={() => props.toggleSegmentPublish(segment._id)}
                checked={segment.isPublished}/>
            </Tooltip>
          </Col>
        </Row>
        </Styles.SegmentTitleWrapper>
        <Styles.ChapterListWrapper>
          {segment.chapters && segment.chapters.map((chapter, chapterIndex) => {
            // return null;
            return (
              <Styles.IndividualChapter
              selected={props.selectedChapter && props.selectedChapter._id === chapter._id}
              key={chapter._id}
              onClick={() => props.selectChapter(segment._id, chapterIndex)}>
                - {chapter.title}
              </Styles.IndividualChapter>
            )
          })}
          <Styles.PlusButton onClick={() => props.toggleAddLessonModal(segment._id, props.index)}>
            +
          </Styles.PlusButton>
          <Divider />
        </Styles.ChapterListWrapper>
      </Styles.IndividualSegmentWrapper>
    )
  }
};

export default Segment;