import React from "react";
import axios from "axios";
import { Redirect, withRouter } from "react-router-dom"

import Cookies from "js-cookie"

import AuthPresentational from "../Presentationals/AuthPresentational"


class AuthContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      screen: "LOGIN",
      email: "",
      password: "",
      passwordConfirm: "",
      message: "",
      loggedIn: false,
      networkCallState: "idle"
    }
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      message: "",
      networkCallState: "idle"
    })
  }

  toggleScreen = (screenName) => {
    this.setState({
      screen: screenName
    })
  }  

  submitForm = () => {
    if(!this.state.email || !this.state.password){
      this.setState({
        message: "fill all fields"
      });
      return;
    }
    let url = '/user/login';
    let data = {
      email: this.state.email,
      password: this.state.password,
      webForm: true
    };
    if(this.state.screen === "SIGNUP"){
      url = '/user/signup';
      data.passwordConfirm = this.state.passwordConfirm
    }
    
    this.setState({
      networkCallState: "pending"
    })

    axios
      .post(url, data)
      .then(resp => {
        Cookies.set('token', resp.data.token);
        Cookies.set('email', resp.data.email ? resp.data.email : resp.data.user?.email);
        if(resp.data.app){
          this.props.history.push(`workspace/${resp.data.app}`)
        }
        this.setState({
          loggedIn: true,
          networkCallState: "resolved"
        })
      })
      .catch(err => {
        this.setState({
          message:err.response?.data?.message? err.response?.data?.message: "Something went wrong" ,
          networkCallState: "rejected"
        })
      })
  }

  generatePasswordToken = () => {
    if(!this.state.email){
      this.setState({
        message: "enter your email"
      });
      return;
    }
    this.setState({
      networkCallState: 'pending'
    })
    axios
      .post('/user/recover', {
        email: this.state.email
      })
      .then(resp => {
        this.setState({
          successMessage: "Email has been sent",
          networkCallState: "resolved"
        })
      })
      .catch(err => {
        this.setState({
          message: err.message,
          networkCallState: 'rejected'
        })
      })
  }

  render() {
    if(this.state.loggedIn){
      return <Redirect to='/' />
    }
    return (
      <AuthPresentational 
        onChange={this.onChange}
        email={this.state.email}
        networkCallState={this.state.networkCallState}
        toggleScreen={this.toggleScreen}
        generatePasswordToken={this.generatePasswordToken}
        password={this.state.password}
        passwordConfirm={this.state.passwordConfirm}
        screen={this.state.screen}
        submitForm={this.submitForm}
        message={this.state.message}
        successMessage={this.state.successMessage}
      />
    )
  }
}

export default withRouter(AuthContainer);