import React, { Component } from 'react';
import { withRouter } from "react-router-dom"

import NavBarPresentational from "../Presentationals/NavBarPresentational"
import Cookies from "js-cookie"

class NavBarContainer extends Component {
  constructor(props) {
    super();
    
  }

  logout = () => {
    Cookies.remove('token');
    this.props.history.push('/auth')
  }

  render(){
    return (
      <NavBarPresentational logout={this.logout}/>
    )
  }
};

export default withRouter(NavBarContainer);