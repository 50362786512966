import React from 'react'
import clonedeep from 'lodash.clonedeep';
import { Button, Modal, Select } from 'antd';
import axios from 'axios';
import { CloseCircleOutlined } from '@ant-design/icons'

import * as Formed from "../../components/StyledItems/Form"
import Sidebar from "../../components/Sidebar"
import * as Styles from "./ItemStructure.style"
import ButtonWithState from "../../components/ButtonWithState"
import Input from "../../components/Input"

const { Option } = Select;
class ItemStructure extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      itemTypes: [],
      selectedItem: {},
      selectedIndex: 0,
      modalVisible: false,
      submitItemState: "idle",
      saveItemStructureStatus: 'idle',
      newItemTitle: ""
    }
  }

  componentDidMount() {
    this.updateItems(0);
  }

  updateItems = (index) => {
    axios
      .get('/itemtype')
      .then(resp => {
        this.setState({
          itemTypes: resp.data.itemTypes,
          selectedItem: resp.data.itemTypes[index],
          selectedIndex: index
        })
      })
      .catch(err => {
        console.error(err)
      })
  }

  changeTitle = (title) => {
    let selectedItem = clonedeep(this.state.selectedItem);
    selectedItem.title = title;
    this.setState({ selectedItem: selectedItem })
  }

  changeStructure = (text, index, key) => {
    let selectedItem = clonedeep(this.state.selectedItem);
    if(key !== "objectId"){
      selectedItem.structure[index]['itemTypeRef'] = null;
    }
    selectedItem.structure[index][key] = text;
    this.setState({ selectedItem: selectedItem, saveItemStructureStatus: 'idle' })
  }

  addToStructure = () => {
    let selectedItem = clonedeep(this.state.selectedItem);
    selectedItem.structure.push({
      key: "",
      dataType: "",
      alias: ""
    })
    this.setState({ selectedItem: selectedItem })
  }

  addItem = () => {
    
  }

  toggleModal = () => {
    this.setState({
      modalVisible: !this.state.modalVisible,
      newItemTitle: ""
    })
  }

  onAddNewItem = () => {
    this.setState({
      submitItemState: "pending"
    })
    axios
      .post('/itemtype', { title: this.state.newItemTitle })
      .then(resp => {
        this.setState({
          submitItemState: "resolved",
          modalVisible: false,
        })
        this.updateItems(this.state.itemTypes.length);
      })
      .catch(err => {
        this.setState({
          submitItemState: "rejected"
        })
      })
  }

  changeNewItemTitle = (title) => {
    this.setState({
      newItemTitle: title
    })
  }

  changeSelectedIndex = (index) => {
    this.setState({
      selectedIndex: index,
      selectedItem: this.state.itemTypes[index]
    })
  }

  saveItemStructure = () => {
    this.setState({
      saveItemStructureStatus: 'pending'
    })
    let { selectedItem } = this.state
    axios
      .patch(`itemtype/${selectedItem._id}`, {
        title: selectedItem.title,
        structure: selectedItem.structure
      })
      .then(resp => {
        this.setState({
          saveItemStructureStatus: 'resolved'
        })
      })
      .catch(err => {
        console.error(err);
        this.setState({
          saveItemStructureStatus: 'rejected'
        })
      })
  }

  removeStructureItem = (index) => {
    let selectedItem = clonedeep(this.state.selectedItem);
    selectedItem.structure.splice(index, 1)
    this.setState({ selectedItem: selectedItem, saveItemStructureStatus: 'idle' }) 
  }

  render() {
    return (
      <Styles.ItemStructureWrapper>
        <Sidebar 
          onButtonClick={this.toggleModal}
          selectedIndex={this.state.selectedIndex}
          changeSelectedIndex={this.changeSelectedIndex}
          itemTypes={this.state.itemTypes}/>
        {this.state.selectedItem ? (
          <Styles.Content>
          <Formed.H2Input
            placeholder="item name"
            onChange={(e) => this.changeTitle(e.target.value)}
            value={this.state.selectedItem?.title ?  this.state.selectedItem?.title : "" }
          />
          {this.state.selectedItem?.structure?.map((item, index) => {
            return (
              <Styles.Item key={index}>
                <div
                  onClick={() => this.removeStructureItem(index)} 
                  style={{display: 'flex', justifyContent: 'flex-end', cursor: 'pointer'}}>
                  <CloseCircleOutlined />
                </div>
                <Input
                  inputStyle={{fontWeight: 'bold'}}
                  placeholder="alias"
                  value={item.alias}
                  onChange={(e) => this.changeStructure(e.target.value, index, 'alias')}
                />
                <Input
                  placeholder="key"
                  value={item.key}
                  onChange={(e) => this.changeStructure(e.target.value, index, 'key')}
                />
                <Select
                  onChange={(value) => this.changeStructure(value, index, 'dataType')}
                  bordered={false}
                  value={item.dataType}
                  placeholder="type"
                  style={{fontFamily: 'Ubuntu', fontSize: '18px', padding:0}}
                >
                  <Option value="number">number</Option>
                  <Option value="string">string</Option>
                  <Option value="objectId">objectId</Option>
                  <Option value="boolean">boolean</Option>
                </Select>
                {item.dataType === "objectId" ? (
                  <Select
                    onChange={(value) => this.changeStructure(value, index, 'itemTypeRef')}
                    bordered={false}
                    value={item.itemTypeRef}
                    style={{fontFamily: 'Ubuntu', fontSize: '18px', padding:0}}
                  >
                    {this.state.itemTypes.map((item, index) => {
                      return (
                        <Option key={item._id} value={item._id}>{item.title}</Option>
                      )
                    })}
                  </Select>
                ) : null}
              </Styles.Item>
            )
          })}
          <Button 
            type="primary" 
            style={{maxWidth: '360px'}}
            onClick={this.addToStructure}>
            Add Item
          </Button>
          <div style={{marginTop: "20px"}}>
            <ButtonWithState
              onClick={this.saveItemStructure}
              idleText={"Save Item"}
              successText="Item Saved"
              failureText={this.props.error ? this.props.error : "Something went wrong"} 
              state={this.state.saveItemStructureStatus}/>
          </div>
        </Styles.Content>
        ) : null}
        <Modal
          title="Add a new Item"
          centered
          visible={this.state.modalVisible}
          footer={null}
          onCancel={() => this.toggleModal()}
        >
          <Input
            placeholder="Name your new item"
            value={this.state.newItemTitle}
            onChange={(e) => this.changeNewItemTitle(e.target.value)} />
          <div style={{marginTop: "20px"}}>
            <ButtonWithState
              onClick={this.onAddNewItem}
              idleText={"Add Item"}
              successText="Item Added"
              failureText={this.props.error ? this.props.error : "Something went wrong"} 
              state={this.state.submitItemState}/>
          </div>
        </Modal>
      </Styles.ItemStructureWrapper>
    )
  }
}

export default ItemStructure