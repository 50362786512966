import React, { useEffect } from "react";

import pattern from "./pattern2.jpg"

import * as Styles from "./AuthStyled";
import { Button as AntdButton } from 'antd'

import Button from "../../../components/ButtonWithState"

const AuthPresentational = (props) => {
  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        props.submitForm();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  if(props.screen === "FORGOT_PASSWORD"){
    return (
      <Styles.Wrapper>
        <Styles.LeftWrapper>
          <Styles.BackgroundImage src={pattern} alt="logo"/>
        </Styles.LeftWrapper>
        <Styles.FormWrapper>
          <Styles.FormContent style={{maxWidth: '300px'}}>
            <Styles.LoginText>
              Forgot your password? <br/>
              Nothing to worry.
              <Styles.InputField 
                name="email"
                type="email"
                onChange={props.onChange}
                placeholder="Enter your email"></Styles.InputField>
            </Styles.LoginText>
            <Styles.ForgotPasswordText>
              We will send you an email containing instructions on how to reset the password. Check your email/spam to find the instructions.
            </Styles.ForgotPasswordText>
            <Button 
              state={props.networkCallState}
              onClick={props.generatePasswordToken}
              text={"SEND EMAIL"} />
            <AntdButton 
              style={{marginLeft: '10px'}}
              onClick={() => props.toggleScreen("LOGIN")}>
              Cancel
            </AntdButton>
          <Styles.LoginText>
            {props.successMessage}
          </Styles.LoginText>
          <Styles.LoginText style={{color:"red"}}>
            {props.message}
          </Styles.LoginText>
          </Styles.FormContent>
        </Styles.FormWrapper>
      </Styles.Wrapper>
    )
  }


  return (
    <Styles.Wrapper>
      <Styles.LeftWrapper>
        <Styles.BackgroundImage src={pattern} alt="logo"/>
      </Styles.LeftWrapper>
      <Styles.FormWrapper>
        <Styles.FormContent>
          {props.screen === "SIGNUP" ? (
            <Styles.Heading>Signup</Styles.Heading>
          ) : (
            <Styles.Heading>Login</Styles.Heading>
          )}
          <Styles.InputField 
            name="email"
            type="email"
            onChange={props.onChange}
            placeholder="Enter your email"></Styles.InputField>
          <Styles.InputField 
            name="password"
            onChange={props.onChange}
            placeholder="Enter password" type="password"></Styles.InputField>
          {props.screen === "SIGNUP" ? (
            <Styles.InputField 
              name="passwordConfirm"
              onChange={props.onChange}
              placeholder="Confirm password" type="password"></Styles.InputField>
          ) : null}

          <Styles.ButtonWrapper>
            <Button 
              state={props.networkCallState}
              onClick={props.submitForm}
              text={props.screen === "SIGNUP" ? "Signup" : "Login"} />
          </Styles.ButtonWrapper>

          {props.screen === "SIGNUP" ? (
            <Styles.LoginText>
              {"I already have an account "}
              <Styles.LoginTextClickable
                onClick={() => {
                  props.toggleScreen("LOGIN");
                }}
              >
                Login
              </Styles.LoginTextClickable>
              {" instead"}
            </Styles.LoginText>
          ) : (
            <Styles.LoginText>
              {" "}
              <Styles.LoginTextClickable
                onClick={() => {
                  props.toggleScreen("SIGNUP");
                }}
              >
                Signup
              </Styles.LoginTextClickable>{" "}
              instead
            </Styles.LoginText>
          )}
          <Styles.ForgotPasswordText>
            <Styles.LoginTextClickable onClick={() => {
              props.toggleScreen("FORGOT_PASSWORD")
            }}>
              I forgot my password
            </Styles.LoginTextClickable>
          </Styles.ForgotPasswordText>
          <Styles.LoginText style={{color:"red"}}>
            {props.message}
          </Styles.LoginText>
        </Styles.FormContent>
      </Styles.FormWrapper>
    </Styles.Wrapper>
  );
};

export default AuthPresentational;
