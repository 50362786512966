import React from "react";
import { Input, Select } from 'antd';
import axios from "axios";


import * as Styles from "./Form.style";
import * as FormStyles from "../../components/StyledItems/Form"

const { Option } = Select;

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  searchForItem = (searchTerm, itemType) => {
    axios
      .get(`/item/search/?searchTerm=${searchTerm}&itemType=${itemType}`)
      .then(resp => {
        this.setState({
          [itemType]: resp.data.item
        })
      })
      .catch(err => {
        console.error(err)
      })
  }

  getItemForId = (id, itemType) => {
    axios
      .get(`/item/${id}`)
      .then(resp => {
        let newArray = []
        if(resp.data.item){
          newArray.push(resp.data.item)
        }
        this.setState({
          [itemType]: newArray
        })
      })
      .catch(err => {
        console.error(err)
      })
  }


  generateInputs = (itemType) => {
    let type;
    let item = this.state[itemType.itemTypeRef]?.find(item => {
      return item?._id === this.props.currentEditingItem[itemType.key]
    });

    if(!item){
      if(this.props.currentEditingItem[itemType.key] && itemType.dataType === 'objectId'){
        this.getItemForId(this.props.currentEditingItem[itemType.key], itemType.itemTypeRef)
      }
    }
    return (
      <Styles.IndividualInput key={itemType._id}>
        <Styles.InputTitle>
          {itemType.alias}
        </Styles.InputTitle>
        {itemType.dataType === "objectId" ? (
          <Select
            showSearch
            value={this.props.currentEditingItem[itemType.key]}
            placeholder={itemType.alias}
            filterOption={false}
            showArrow={false}
            defaultActiveFirstOption={false}
            style={{width: '100%' }}
            onChange={(e) => {this.props.onChangeItemValue(itemType.key, e)}}
            onSearch={(searchTerm) => this.searchForItem(searchTerm, itemType.itemTypeRef)}
          >
            {this.state[itemType.itemTypeRef]?.map(option =>  <Option key={option._id}>{option.title}</Option>)}
          </Select>
        ) : (
          <Input 
            style={{
              fontFamily: '"Ubuntu", sans-serif',
              fontWeight: 600
            }}
            placeholder={itemType.alias}
            onChange={(e) => this.props.onChangeItemValue(itemType.key, e.target.value)}
            type={type}
            value={this.props.currentEditingItem[itemType.key]}
            name={itemType.key}/>
        )}
      </Styles.IndividualInput>
    )
  }

  render(){
    return (
      <Styles.FormCardWrapper>
        {this.props.itemType ? (
          <React.Fragment>
            <Styles.FormContent>
              <FormStyles.SubHeadingInput
                placeholder="Item name"
                value={this.props.currentEditingItem.title}
                onChange={(e) => this.props.onChangeItemValue('title', e.target.value)}
              />
              {this.props.itemType?.structure?.map((itemType) => {
                return this.generateInputs(itemType)
              })}
            </Styles.FormContent>
            <Styles.SaveButton
              onClick={this.props.onSaveItem}
            >
              Save & Next
            </Styles.SaveButton>
          </React.Fragment>
        ) : null}
      </Styles.FormCardWrapper>
    )
  }
};

export default Form;