import React from 'react'
import * as Styles from "./Input.style"

function Input (props) {
  return (
    <Styles.InputWrapper>
      <Styles.InputTitle>
        {props.title}
      </Styles.InputTitle>
      <Styles.Input
        style={{...props.inputStyle}}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
      >
      
      </Styles.Input>
    </Styles.InputWrapper>
  )
}

export default Input;