import styled from "styled-components"

export const ItemStructureWrapper = styled.div`
  background-color: #f2f7ff;
  display: flex;
  flex-direction: row;
`

export const CardWrapper = styled.div`

`;

export const Content = styled.div`
  padding: 16px;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-grow:1;
  flex-direction: column;
`;

export const Item = styled.div`
  max-width: 360px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: white;
  margin: 10px 0px;
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19); */
  /* border-radius: 5px; */
`