import React from "react";
import {
  withRouter
} from "react-router-dom";

import * as Styles from "./Share.style"

let BASE_URL = "https://api.makethemstay.com";
if(process.env.NODE_ENV === "development"){
  BASE_URL = "http://localhost:3001";
}
// const BASE_URL = ""

const Share = props => {
  return (
    <Styles.PageWrapper>
      <Styles.Heading>
        Your embed code
      </Styles.Heading>
      <Styles.Content>
        Place the following code in your page's HTML where you want your form to appear.
      </Styles.Content>
      <Styles.CodeWrapper>
        <code style={{whiteSpace: 'pre-wrap'}}>
          {`<div class="mts-widget" id=${props.match.params.chapterId} url="${BASE_URL}/chapter/with-session/${props.match.params.chapterId}" baseUrl="${BASE_URL}"></div>
<script src="${BASE_URL}/widget/index.js"></script>`}
        </code>
      </Styles.CodeWrapper>
      <Styles.Heading style={{marginTop: "25px"}}>
        Embedding for wordpress
      </Styles.Heading>
      <Styles.Content>
        If you're using Wordpress, there are some additional steps to be taken.
      </Styles.Content>
      <Styles.Content>
        Go to <a href="https://makethemstay.medium.com/publishing-your-form-on-wordpress-8dad214587f5" target="_blank">this article</a> and follow the steps provided.
      </Styles.Content>
      <Styles.Content>
        This is the unique code for this form which needs to be pasted in HTML block.
      </Styles.Content>
      <Styles.CodeWrapper>
        <code style={{whiteSpace: 'pre-wrap'}}>
          {`<div class="mts-widget" id=${props.match.params.chapterId} url="${BASE_URL}/chapter/with-session/${props.match.params.chapterId}" baseUrl="${BASE_URL}"></div>`}
        </code>
      </Styles.CodeWrapper>
    </Styles.PageWrapper>
  )
}

export default withRouter(Share)