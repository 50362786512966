import styled from "styled-components"

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Heading = styled.div`
  font-family: Ubuntu, sans-serif;
  font-size: 1.4rem;
  font-weight: 800;
  padding: 15px;
`