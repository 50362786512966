import styled from "styled-components";

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`

export const Navbar = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  border-bottom: 1px solid #d2d2d2;
`

export const Heading = styled.div`
  font-family: Ubuntu, sans-serif;
`

export const IndividualLink = styled.div`
  padding: 10px;
`

export const LinkWrapper = styled.div`
  display: flex;
`