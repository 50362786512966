import styled from 'styled-components'
import { color } from "styled-system"

export const ContentWrapper = styled.div`
  background-color: rgb(250, 250, 250);
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: scroll;
`;

export const SegmentWrapper = styled.div`
  margin-left: 25px;
  padding-top: 45px;
`;

export const SegmentTitle = styled.div`
  font-size: 22px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
`;

export const FormIcon = styled.div`
  width: 156px;
  height: 188px;
  background-color: darkslategrey;
  border-radius: 10px;
  margin-right: 25px;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 2px 12px;
  &:hover{
    transition: opacity 0.3s ease-in-out 0s;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 2px 12px, rgba(0, 0, 0, 0.04) 0px 8px 14px, rgba(0, 0, 0, 0.02) 0px 12px 16px;
  }
  display: flex;
  flex-direction: column;
  ${color};
`;

export const FormIconTop = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  width: 100%;
  justify-content: flex-end;
`;

export const PlusIcon = styled.div`
  font-size: 48px;
  color: white;
`

export const FormIconBottom = styled.div`
  height: 70px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 10px;
  background-color: white;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-family: 'Ubuntu';
  text-align: center;
`

export const FormTitle = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: left;
  height: 100%;
  overflow: hidden;
  align-items: center;
`

export const MoreButton = styled.div`
  padding: 10px 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 4px;
  &:hover {
    background-color: grey;
  }
`

export const FormsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Type = styled.div`
  display: flex;
  background-color: white;
  margin: 10px;
  padding: 1px 9px;
  border-radius: 3px;
  font-family: 'Ubuntu';
  font-size: 12px;
`;

export const AddWorkspaceButton = styled.div`
  /* background-color: white; */
  /* box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 2px 12px; */
  padding: 5px;
  border-radius: 3px;
  cursor: pointer;
`;

export const AddWorkspace = styled.div`
  display: flex;
  margin-left: 25px;
  margin-top: 50px;
`