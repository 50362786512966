import React from 'react';
import { Button } from 'antd';

import * as Styles from "./Sidebar.style"

class Sidebar extends React.Component {
  render() {
    return (
      <Styles.SidebarWrapper>
        <Styles.Count>
          21 Items
        </Styles.Count>
        <Styles.ItemList>
          {this.props.itemTypes.map((item, index) => (
            <Styles.Item 
              onClick={() => this.props.changeSelectedIndex(index)}
              selected={index === this.props.selectedIndex} 
              key={index}>
              {item.title}
            </Styles.Item>
          ))}
        </Styles.ItemList>
        <div style={{marginTop: '15px'}}>
          <Button 
            type="primary" 
            style={{marginRight: '10px', marginLeft: '10px'}}
            onClick={this.props.onButtonClick}>
            Add Item
          </Button>
        </div>
      </Styles.SidebarWrapper>
    )
  }
}

export default Sidebar;