import styled from "styled-components"

export const InputWrapper = styled.div`
  margin-top: 10px;
`

export const Input = styled.input`
  border: 0;
  outline: 0;
  font-size: 18px;
  font-family: Ubuntu, sans-serif;
  width: 100%;
  background-color: transparent;
  line-height: 1;
  padding: 0px 11px;
`;

export const InputTitle = styled.div`
  font-size: 10px;
  color: #7c8db5;
  
`