import React from "react";
import {
  Navbar,
  NavbarBrand,
  Nav
} from "reactstrap"
import { DownOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';

import Cookies from "js-cookie"

import * as Styles from "./Navbar.style"

const menu = (props) => {
  return (
    <Menu>
      <Menu.Item onClick={props.logout}>
        Logout
      </Menu.Item>
    </Menu>
  )
};

const NavBarPresentational = props => {
  return (
    <Navbar color="#f5f6f8" light expand="md">
      <NavbarBrand href="/">makethemstay</NavbarBrand>
      <Nav className="mr-auto" navbar>

      </Nav>
      {/* <UncontrolledDropdown nav inNavbar style={{listStyle: 'none'}}>
        <DropdownToggle nav caret>
          {Cookies.get('email')}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem  onClick={props.logout}>
            Logout
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown> */}
      <Dropdown overlay={() => menu(props)}>
        <Styles.EmailAnchor onClick={e => e.preventDefault()}>
         <Styles.DownSpan>{Cookies.get('email')}</Styles.DownSpan> <DownOutlined />
        </Styles.EmailAnchor>
      </Dropdown>
  
  </Navbar>
  )
}

export default NavBarPresentational;