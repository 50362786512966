import React from "react";
import axios from "axios"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter,
  useRouteMatch
} from "react-router-dom";
import * as Styles from "./Responses.style"
import { Spin, Select } from "antd"
import { LoadingOutlined } from '@ant-design/icons';
import DateSelector from "./DateSelector"
import moment from "moment"

let today = moment();
let firstDay = moment().subtract(7, 'd');


const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;


const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

class Response extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      sessions: [],
      selectedSession: {},
      initialLoad: 'pending',
      dates: [firstDay, today],
    }
  }

  componentDidMount() {
    let { chapterId } = this.props.match.params
    let chapterData = axios.get(`/chapter/${chapterId}`);
    let sessionData = axios.get(`/session/${chapterId}?startDate=${this.state.dates[0].toISOString()}&endDate=${this.state.dates[1].toISOString()}`)
    axios.all([chapterData, sessionData])
      .then(resp => {
        let particles = {}
        resp[0].data.particles.forEach(particle => {
          particles[particle._id] = particle;
        })

        this.setState({
          chapter: resp[0].data,
          sessions: resp[1].data.sessions,
          particles: particles,
          initialLoad: 'completed',
          selectedSession: resp[1].data.sessions?.[0] ? resp[1].data.sessions[0] : {}
        })
      })
      .catch(err => {
        console.error("erro ==< ", err)
      })
  }

  getSessionData = () => {
    if(!this.state.dates[0] || !this.state.dates[1]){
      return;
    }
    let { chapterId } = this.props.match.params
    let sessionData = `/session/${chapterId}?startDate=${this.state.dates[0].toISOString()}&endDate=${this.state.dates[1].toISOString()}`;
    axios
      .get(sessionData)
      .then(resp => {
        this.setState({
          sessions: resp.data.sessions,
          selectedSession: resp.data.sessions?.[0] ? resp.data.sessions[0] : {}
        })
      })
  }

  setSelectedDates = (dates) => {
    this.setState({
      dates: dates
    }, () => this.getSessionData())
  }

  getDate = (date) => {
    let dateObj = new Date(date);
    return `${dateObj.getDate()} ${months[dateObj.getMonth()]}`
  }

  onSelectItem = (session) => {
    this.setState({
      selectedSession: session
    })
  }

  findSelectedOption = (response) => {
    if(response.text){
      return response.text;
    }
    let selectedOption = this.state.particles[response.particle]?.options?.find((option) => {
      return option._id === response.selectedOption
    })
    return selectedOption?.text;
  }

  renderContent = () => {
    if(this.state.sessions.length === 0) {
      return (
        <Styles.NoResponses>No responses during this period</Styles.NoResponses>
      )
    }
    return (
      <Styles.ContentWrapper>
        <Styles.Sidebar>
          {this.state.sessions.map((session) => (
            <Styles.Date 
              key={session._id} 
              isSelected={session._id === this.state.selectedSession._id}
              onClick={() => {
                this.onSelectItem(session)
              }}>
              {this.getDate(session.createdAt)}
            </Styles.Date>
          ))}
        </Styles.Sidebar>
        <Styles.SelectorWrapper>
          <Select 
          value={this.state.selectedSession?._id}
          style={{width: '300px'}} 
          onChange={(sessionId) => {
            let session = this.state.sessions.find(session => session._id === sessionId)
            this.onSelectItem(session)
          }}>
            {this.state.sessions.map((session) => {
              return (
                <Option value={session._id} key={session._id}>{this.getDate(session.createdAt)}</Option>
              )
            })}
          </Select>
        </Styles.SelectorWrapper>
        {this.state.selectedSession._id ? (
          <Styles.ResponsePadding>
            <Styles.ResponseWrapper>
              <Styles.Date>
                {this.getDate(this.state.selectedSession.createdAt)}
              </Styles.Date>
              {this.state.selectedSession?.responses?.map((response) => {
                if(!this.state.particles[response.particle]){
                  return null;
                }
                return (
                  <Styles.IndividualResponse key={response._id}>
                    <Styles.Question>
                      {this.state.particles[response.particle]?.question || this.state.particles[response.particle]?.title || this.state.particles[response.particle]?.text}
                    </Styles.Question>
                    <Styles.Answer>
                      {this.findSelectedOption(response)}
                    </Styles.Answer>
                  </Styles.IndividualResponse>
                )
              })}
            </Styles.ResponseWrapper>
          </Styles.ResponsePadding>
        ) : null}
      </Styles.ContentWrapper>
    )
  }

  render() {
    if(this.state.initialLoad === 'pending' ){
      return (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1
        }}>
          <Spin indicator={antIcon} />
        </div>
      )
    }

    return (
        <Styles.ResponsePageWrapper>
          <Styles.DateWrapper>
            <DateSelector 
              setSelectedDates={this.setSelectedDates}
              dates={this.state.dates}/>
          </Styles.DateWrapper>
          {this.renderContent()}
        </Styles.ResponsePageWrapper>
    )
  }
}

export default withRouter(Response);