import React from 'react';
import { Modal, Select } from 'antd';
import axios from 'axios';

import * as Styles from "./NewFormModal.style";

const { Option } = Select;

class NewWorkspaceModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      error: ""
    }
  }

  onChangeTitle = (title) => {
    this.setState({ title: title })
  }

  onCancel = () => {
    this.props.addWorkspaceModalClose();
    this.setState({
      title: "",
      error: ""
    })
  }
  
  onSubmit = () => {
    let { title } = this.state;
    if(!title){
      this.setState({
        error: "Please give your workspace a name"
      })
      return;
    }
    axios
      .post('/segment', { 
        title: title,
        appId: this.props.appId,
        rank: this.props.workspaceRank
      })
      .then(resp => {
        this.props.addWorkspaceModalClose();
        this.props.fetchChapterList();
      })
      .catch(err => {
        console.error("ERROR", err)
      })
  }

  render() {
    return (
      <Modal
        title="Add a new workspace"
        visible={this.props.modalOpen}
        onOk={this.onSubmit}
        onCancel={this.onCancel}
      >
        <Styles.Question>Give a name to your workspace</Styles.Question>
        <Styles.Description>
            {`Workspaces can be used to club similar types of items together. For example if you want all questions about laptops together, then you can do that using workspaces.`}
          </Styles.Description>
        <Styles.Input 
          placeholder="Workspace name here"
          onChange={(e) => this.onChangeTitle(e.target.value)}
          value={this.state.title}>

        </Styles.Input>
        <Styles.Error>{this.state.error}</Styles.Error>
      </Modal>
    )
  }
}

export default NewWorkspaceModal;