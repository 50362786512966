import React from "react";
import { Col, Button, Form, FormGroup } from "reactstrap";

import trash from "./trash.png";

import * as Styles from "./NotesBuilderStyled";
import * as Formed from "../../../components/StyledItems/Form";
import ButtonWithState from "../../../components/ButtonWithState";

const NotesArrayBuilderPresentational = (props) => {
  return (
    <Styles.NotesWrapper>
      <Formed.ParticleHeading>Stacked Notes</Formed.ParticleHeading>
      <Form>
        <FormGroup row>
          <Col sm={10}>
            {props.stackedNotes.map((note, index) => {
              return (
                <FormGroup row key={`option-${props.particleId}-${index}`}>
                <Col sm={10}>
                <Formed.MultilineInput
                    type="text"
                    name={`option-${props.particleId}-${index}`}
                    id={`option-${props.particleId}-${index}`}
                    value={note.text}
                    rows="5"
                    onChange={(e) => props.onChangeStackedNote(e, index)}
                    // onKeyDown={(e) => props.keyPressHandler(e, index)}
                    placeholder={`enter content for note ${index + 1}`}
                  />
                </Col>
                <Col sm={1}>
                  <img
                    src={trash}
                    alt="trash can"
                    style={{ height: "21px", cursor: "pointer" }}
                    onClick={() => props.deleteStackedNote(index)}
                  />
                </Col>
                </FormGroup>
              );
            })}
          </Col>
        </FormGroup>
        <Button
          onClick={() =>
            props.addStackedNote(
              `option-${props.particleId}-${props.stackedNotes.length}`
            )
          }
        >
          Add New Note
        </Button>
      </Form>
      <Styles.BottomButtonWrapper>
        <FormGroup style={{ paddingRight: "15px" }}>
          <Button color="danger" onClick={props.deleteNote}>
            delete
          </Button>
        </FormGroup>
        <FormGroup>
          <ButtonWithState
            color="primary"
            text="save"
            successText="saved"
            failureText="failed"
            state={props.networkState}
            onClick={props.saveNotes}
          />
          {/* <Button color="primary" onClick={props.saveNotes}>save</Button> */}
        </FormGroup>
      </Styles.BottomButtonWrapper>
    </Styles.NotesWrapper>
  );
};

export default NotesArrayBuilderPresentational;
