import styled from "styled-components"

export const SidebarWrapper = styled.div`
  width: 360px;
  background-color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Count = styled.div`
  font-size: 24px;
  padding-top: 20px;
  font-family: 'Ubuntu', serif;
  padding-left: 18px;
`;

export const ItemList = styled.div`
  padding-top: 20px;
`;

export const Item = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 500;
  cursor: pointer;
  background-color: ${props => props.selected ? "#ededed" : "white"};
  color: ${props => props.selected ? "#000" : "black"};
  font-size: 18px;
  padding-left: 18px;
  font-family: 'Ubuntu', serif;
`