import styled from "styled-components"

export const ItemWrapper = styled.div`
  width: 100%;
  height: 100vh;
  /* background-color: orange; */
  display: flex;
`;

export const MiddleSection = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const TableText = styled.div`
  font-family: 'Ubuntu', sans-serif;
`;

export const TableHeader = styled.div`
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  font-weight: 600;
`

export const ClearButton = styled.div`
  width: 100%;
  background-color: #53b578;
  color: #fff;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
`;