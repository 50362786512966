import React from 'react';
import axios from "axios"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  withRouter,
  useRouteMatch
} from "react-router-dom";

import * as Styles from "./Responses.style"
import Response from "./Responses"
import Summary from "./Summary"
import Insights from "./Insights"

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const columns = [{
  title: "Sessions",
  dataIndex: "createdAt",
  key: "sessions",
  render: date => {
    let dateObj = new Date(date);
    return `${dateObj.getDate()} ${months[dateObj.getMonth()]}`
  }
}]


class ResponseScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  

  

  render() {

    // let { path, url } = useRouteMatch();
    let { path, url } = this.props.match;
    return (
      <Styles.PageWrapper>
        <Styles.Navbar>
          <Styles.LinkWrapper>
            <Styles.IndividualLink>
              <NavLink exact activeClassName="on-selected" to={`${url}`}>Summary</NavLink>
            </Styles.IndividualLink>
            <Styles.IndividualLink>
              <NavLink activeClassName="on-selected" to={`${url}/summary`}>Overview</NavLink>
            </Styles.IndividualLink>
            <Styles.IndividualLink>
              <NavLink activeClassName="on-selected" to={`${url}/responses`}>Responses</NavLink>
            </Styles.IndividualLink>
          </Styles.LinkWrapper>
        </Styles.Navbar>
        <Styles.Wrapper>
          <Switch>
            <Route exact path={path}>
              <Insights />
            </Route>
            <Route path={`${path}/summary`}>
              <Summary />
            </Route>
            <Route path={`${path}/responses`}>
              <Response
                sessions={this.state.sessions}
                selectedSession={this.state.selectedSession}
                onSelectItem={this.onSelectItem}
                getDate={this.getDate}
                particles={this.state.particles}
                findSelectedOption={this.findSelectedOption} />
            </Route>
          </Switch>
      </Styles.Wrapper>    
        </Styles.PageWrapper>
    )
  }
}

export default withRouter(ResponseScreen)
