import styled from "styled-components";

export const PageWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
`;

export const ErrorText = styled.div`
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: red;
  padding-top: 20px;
`;

export const Text = styled.div`
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 19px;
  max-width: 320px;
`

export const InputField = styled.input`
  /* font-family: 'Ubuntu', serif; */
  font-weight: 600;
  border: none;
  border-bottom: 2px solid #a2a2a2;
  width: 100%;
  max-width: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  margin: 30px 0px;
  font-family: 'Ubuntu', sans-serif;
`;

export const ButtonWrapper = styled.div`
  max-width: 320px;
`