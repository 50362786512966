import React from "react";
import { Route, Redirect } from "react-router-dom";
import axios from "axios";

import Cookies from "js-cookie"

const PrivateRoute = ({ component: Component, ...rest }) => {
  if(Cookies.get('token')){
    axios.defaults.headers.common['authorization'] = `Bearer ${Cookies.get('token')}`
  }
  return (
    <Route {...rest} render={(props) => (
      Cookies.get('token')
        ? <Component {...props} />
        : <Redirect to='/auth' />
    )} />
  )  
}

export default PrivateRoute;