import styled from "styled-components";

export const Question = styled.div`
  font-size: 16px;
  font-family: 'Ubuntu', sans-serif;
  padding-left: 11px;
  margin-top: 10px;
  padding-bottom: 5px;
`;

export const Description = styled.div`
  font-size: 12px;
  font-family: 'Ubuntu', sans-serif;
  white-space: pre-line;
  padding-left: 11px;
`;

export const TextPara = styled.p`
  margin-bottom: 0.3rem;
`

export const Input = styled.input`
  border: 0;
  outline: 0;
  font-size: 18px;
  width: 100%;
  background-color: transparent;
  padding-left: 11px;
`;

export const Error = styled.div`
  font-size: 14px;
  color: red;
  font-family: 'Ubuntu', sans-serif;
  padding-left: 11px;
`