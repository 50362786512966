import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter,
  useRouteMatch
} from "react-router-dom";
import axios from "axios";
import { Collapse } from 'antd';

import * as Styles from "./Responses.style";
import { Spin } from "antd"
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

const { Panel } = Collapse;

class Summary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      particles: [],
      initialLoad: 'pending'
    }
  }

  componentDidMount() {
    let { chapterId } = this.props.match.params
    axios
      .get(`/session/by-particle?chapter=${chapterId}`)
      .then(resp => {
        this.setState({
          particles: resp.data.particles,
          initialLoad: 'completed'
        })
      })
      .catch(err => {
        console.error(err)
      })
  }

  responseCreator = (particle) => {
    if(particle.type === "POLL" || particle.type === "MCQ") {
      particle.options && particle.options.forEach((option, index) => {
        let numberOfSelections = particle.responses[option._id] ? particle.responses[option._id] : 0;
        let totalResponses = particle.totalResponses ? particle.totalResponses : 1;
        particle.options[index].percentage = Math.round((numberOfSelections / totalResponses) * 1000) / 10;
      })
      
      return particle.options?.map((option) => {
        return (
          <div key={option._id}>
            <Styles.ProgressContainer>
              <Styles.ProgressFiller completed={option.percentage}>
                {option.percentage}%
              </Styles.ProgressFiller>
                {option.text}
            </Styles.ProgressContainer>
          </div>
        )
      })
    } else if (particle.type === "TEXT") {
      return (
        <Collapse ghost>
          <Panel header={<Styles.Ubuntu>See Responses</Styles.Ubuntu>}>
            <div>
              {particle.responses.map((response) => {
                return (
                  <Styles.Ubuntu key={response._id}>
                    {response.text}
                  </Styles.Ubuntu>
                )
              })}
            </div>
          </Panel>
        </Collapse>
      )
    } else {
      return null;
    }
  }

  render() {
    if(this.state.initialLoad === 'pending' ){
      return (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1
        }}>
          <Spin indicator={antIcon} />
        </div>
      )
    }
    return (
      <Styles.SummaryList>
        {this.state.particles.map((particle) => {
          return (
            <div key={particle._id}>
              <Styles.ResponsePadding>
                <Styles.ResponseWrapper>
                  <Styles.Question>
                    {particle.title || particle.question}
                  </Styles.Question>
                  {this.responseCreator(particle)}
                </Styles.ResponseWrapper>
              </Styles.ResponsePadding>
            </div>
          )
        })}
      </Styles.SummaryList>
    )
  }
};

export default withRouter(Summary);