import React from "react";
import { 
  Button, 
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  Form, 
  FormGroup,
  Label,
  Input,
  Col
} from 'reactstrap';
import ButtonWithState from "../../ButtonWithState"


const AddNewModal = (props) => {
  return (
    <Modal 
    isOpen={props.showModal} 
    >
    <ModalHeader>{props.modalHeading}</ModalHeader>
    <ModalBody>
      <Form>
        <FormGroup row>
          <Label sm={3} for="title">Title</Label>
          <Col sm={9}>
            <Input 
              type="text" 
              name="title" 
              id="title" 
              value={props.title}
              placeholder={`enter ${props.modalCategory} title`}
              onChange={props.formOnChange} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3} for="description">Description</Label>
          <Col sm={9}>
            <Input 
              type="text" 
              name="description" 
              id="description" 
              value={props.description}
              placeholder={`enter ${props.modalCategory} description`}
              onChange={props.formOnChange} />
          </Col>
        </FormGroup>
        {props.showType? (
          <FormGroup row>
          <Label sm={3} for="description">Type (leave blank)</Label>
          <Col sm={9}>
            <Input 
              type="select" 
              name="type" 
              id="type" 
              value={props.type}
              placeholder="enter chapter type"
              onChange={props.formOnChange}>
                <option></option>
            <option>LESSON</option>
            <option>GAME</option>
            </Input>
          </Col>
        </FormGroup>
        ) : null}
      </Form>
    </ModalBody>
    <ModalFooter>
      <ButtonWithState
        onClick={props.submitNewChapter}
        idleText={"Add chapter"}
        successText="Chapter Added"
        failureText={props.error ? props.error : "Something went wrong"} 
        state={props.submitChapterState}/>{' '}
      <Button color="secondary" onClick={props.toggleAddLessonModal}>Cancel</Button>
    </ModalFooter>
  </Modal>
  )
};

export default AddNewModal;