import React, { useState } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
const { RangePicker } = DatePicker;

const DateSelector = (props) => {
  // const [dates, setDates] = useState([]);
  let dates = props.dates;
  const [hackValue, setHackValue] = useState();
  const [value, setValue] = useState();
  const disabledDate = current => {
    if (!dates || dates.length === 0) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;
    return tooEarly || tooLate;
  };

  const onOpenChange = open => {
    if (open) {
      setHackValue([]);
      props.setSelectedDates([]);
    } else {
      setHackValue(undefined);
    }
  };

  return (
    <RangePicker
      value={hackValue || value}
      defaultValue={[moment(), moment().subtract(7, 'd')]}
      disabledDate={disabledDate}
      onCalendarChange={val => props.setSelectedDates(val)}
      onChange={val => setValue(val)}
      onOpenChange={onOpenChange}
    />
  );
};

export default DateSelector;