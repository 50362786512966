import styled from "styled-components";
import { color } from "styled-system"

export const Wrapper = styled.div`
  background-color: #fafafa;
  height: 100vh;
`

export const HomeBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
`;

export const AppWrapper = styled.div`
  width: 156px;
  height: 188px;
  background-color: #fff;
  border-radius: 10px;
  margin-left: 25px;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 2px 12px;
  &:hover{
    transition: opacity 0.3s ease-in-out 0s;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 2px 12px, rgba(0, 0, 0, 0.04) 0px 8px 14px, rgba(0, 0, 0, 0.02) 0px 12px 16px;
  }
  ${color};
`

export const AppText = styled.div`
  font-family: 'Ubuntu', sans-serif;
  font-weight: 800;
  width: 100%;
  color: #262627;
`;

export const AddChapterCross = styled.div`
  font-family: 'Ubuntu', sans-serif;
  font-weight: 800;
  color: #262627;
  font-size: 46px;
  width: 100%;
`