import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

import * as Styles from "./HomeStyled";

import NavBar from "../../../components/NavBar";

const HomePresentational = (props) => {
  return (
    <Styles.Wrapper>
      <NavBar history={props.history} />
      <Styles.HomeBody>
        {props.apps.map((app) => {
          return (
            <Styles.AppWrapper onClick={() => props.goToApp(app._id)} key={app._id}>
              <Styles.AppText>{app.title}</Styles.AppText>
            </Styles.AppWrapper>
          );
        })}
        <Styles.AppWrapper onClick={props.toggleModal} bg="#4fb0ae">
          <Styles.AddChapterCross>+</Styles.AddChapterCross>
          <Styles.AppText>Click to create a new app</Styles.AppText>
        </Styles.AppWrapper>
      </Styles.HomeBody>
      <Modal isOpen={props.isOpenModal} toggle={props.toggleModal}>
        <ModalHeader toggle={props.toggleModal}>Create a new app</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label for="title" sm={3}>
                App name
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  name="title"
                  id="title"
                  onChange={props.onChange}
                  placeholder="Enter app name"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="description" sm={3}>
                App description
              </Label>
              <Col sm={9}>
                <Input
                  type="textarea"
                  name="description"
                  id="description"
                  onChange={props.onChange}
                  placeholder="Enter app description"
                />
              </Col>
            </FormGroup>
            <div>You can change these later</div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={props.addNewChapter}>
            Add App
          </Button>{" "}
          <Button color="secondary" onClick={props.toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Styles.Wrapper>
  );
};

export default HomePresentational;
