import React from "react";
import * as Styles from "./ResetPassword.style"
import axios from "axios"
import { Spin } from "antd"
import { LoadingOutlined } from '@ant-design/icons';
import Button from "../../components/ButtonWithState"

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "RESET_PASSWORD",
      email: "",
      password: "",
      passwordConfirm: "",
      message: "",
      networkCallState: "idle",
    }
  }

  componentDidMount() {
    console.log(this.props.match.params)
    axios
      .get(`/user/reset/?token=${this.props.match.params.token}`)
      .then(resp => {
        this.setState({
          email: resp.data.email
        })
      })
      .catch(err => {
        this.setState({
          page: "ERROR",
          message: err.response?.data?.message ? err.response.data.message : "Something went wrong"
        })
      })
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      message: "",
      networkCallState: "idle"
    })
  }

  submitForm = () => {
    if(this.state.password != this.state.passwordConfirm) {
      this.setState({
        message: "Passwords do not match"
      })
      return;
    }
    if(this.state.password.length < 8) {
      this.setState({
        message: "Password must be at least 8 characters"
      })
      return;
    }
    this.setState({
      networkCallState: "pending",
      message: ""
    })
    axios
      .post('/user/reset', {
        password: this.state.password,
        passwordConfirm: this.state.passwordConfirm,
        resetPasswordToken: this.props.match.params.token
      })
      .then(resp => {
        this.setState({
          networkCallState: 'resolved',
          page: "COMPLETED",
          message: ""
        })
      })
      .catch(err => {
        console.error("ERR ", err, err.response)
        this.setState({
          message: err.response?.data?.message,
          networkCallState: 'rejected'
        })
      })
  }

  render() {
    if(this.state.page === "LOADING") {
      return (
        <Styles.PageWrapper>
          <Spin indicator={antIcon} />
        </Styles.PageWrapper>
      )
    } else if (this.state.page === "ERROR") {
      return (
        <Styles.PageWrapper>
          <Styles.ErrorText>
            {this.state.message}
          </Styles.ErrorText>
        </Styles.PageWrapper>
      )
    } else if (this.state.page === "COMPLETED") {
      return (
        <Styles.PageWrapper>
          <Styles.Text>
            Your password has been successfully changed. Kindly login again. You can click on this <a href="https://dashboard.makethemstay.com">link to login</a>
          </Styles.Text>
        </Styles.PageWrapper>
      )
    }
    return (
      <Styles.PageWrapper>
        <Styles.Text>
          Hi, enter new password for {this.state.email}
        </Styles.Text>
        <Styles.InputField 
          name="password"
          onChange={this.onChange}
          placeholder="Enter password" type="password"/>
        <Styles.InputField 
          name="passwordConfirm"
          onChange={this.onChange}
          placeholder="Confirm password" type="password"/>
        <Styles.ButtonWrapper>
          <Button 
            state={this.state.networkCallState}
            onClick={this.submitForm}
            text={"Submit"} />
        </Styles.ButtonWrapper>
        <Styles.ErrorText>{this.state.message}</Styles.ErrorText>
      </Styles.PageWrapper>
    )
  }
}

export default ResetPassword;