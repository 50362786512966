import styled from "styled-components";

export const FormCardWrapper = styled.div`
  background-color: #f5f5f5;
  border-radius: 10px;
  width: 300px;
  box-sizing: border-box;
  margin: 20px;
  padding: 15px;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const InputTitle = styled.div`
  color: #757575;
  font-size: 12px;
  font-family: "Ubuntu", sans-serif;
  font-weight: 600;
`;

export const IndividualInput = styled.div`
  padding-top: 10px;

`

export const SaveButton = styled.div`
  width: 100%;
  background-color: #53b578;
  color: #fff;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
`