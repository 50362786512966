import React from 'react';
import axios from 'axios';
import ParticleBuilder from "../../components/ParticleBuilder"

class FormBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedChapter: {}
    }
  }

  componentDidMount() {

  }

  render() {
    return (
      <div style={{ overflow: 'auto', display: 'flex', flexGrow: 1}}>
        <ParticleBuilder 
          selectedChapter={{ _id: "loremipsum 5ea6936ca686416bff795f1c"}} 
        />
        {/* the selectedChapter above is not doing anything. remove it later */}
      </div>
    )
  }
}

export default FormBuilder;