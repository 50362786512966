import styled from "styled-components";

export const SidebarWrapper = styled.div`
  background-color: #53b578;
  border-radius: 10px;
  width: 300px;
  box-sizing: border-box;
  margin: 20px;
  padding: 15px;
  padding-top: 25px;
`;

export const Heading = styled.div`
  font-size: 32px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  margin-bottom: 50px;
  color: white;
`;

export const Item = styled.div`
  font-size: 24px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  margin-top: 15px;
  color: ${props => props.selected ? "white" : "#a9dabc"};
  background-color: ${props => props.selected ? "#60bb82" : "transparent"};
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer;
`;