import React from 'react';
import axios from 'axios';

import Navbar from "../../components/NavBar";
import Contents from "./Contents"

import * as Styles from "./Workspace.styles"
import NewFormModal from './NewFormModal';
import NewWorkspaceModal from './NewWorkspaceModal'
import { Spin } from "antd"
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

class Workspace extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      segments: [],
      app: {},
      modalOpen: false,
      selectedWorkspace: "",
      newRank: 100,
      workspaceModalOpen: false,
      workspaceRank: 100,
      initialLoad: 'pending'
    }
  }

  componentDidMount(){
    this.fetchChapterList();
  }

  fetchChapterList = () => {
    axios
      .get(`/app/${this.props.match.params.appId}`)
      .then(resp => {
        let { chapters, segments } = resp.data;
        if (!chapters) chapters = [];
        if (!segments) segments = [];
        chapters.forEach(chapter => {
          let segment = segments.find(item => {
            return item._id === chapter.segment
          });
          if(segment){
            if(!segment.chapters){
              segment.chapters = [chapter]
            } else {
              segment.chapters.push(chapter)
            }
          }
        })
        if(resp.data.app){
          this.setState({ 
            // chapters,
            segments,
            app: resp.data.app,
            initialLoad: 'completed',
            workspaceRank: segments.length ? segments[segments.length - 1].rank + 100 : 100
          });
        }
      })
      .catch(err => {
        console.error(err.response)
      })
  }

  addChapterModalOpen = (workspaceId, rank) => {
    this.setState({
      selectedWorkspace: workspaceId,
      modalOpen: true,
      newRank: rank
    });
  }

  addChapterModalClose = () => {
    this.setState({
      selectedWorkspace: "",
      modalOpen: false
    })
  }

  goToChapter = (chapterId) => {
    this.props.history.push(`/form/${chapterId}`)
  }

  addWorkspaceModalOpen = () => {
    this.setState({
      workspaceModalOpen: true,

    })
  }

  addWorkspaceModalClose = () => {
    this.setState({
      workspaceModalOpen: false
    })
  }

  deleteChapter = (id) => {
    axios.delete(`/chapter/${id}`)
    .then(resp => {
      this.fetchChapterList()
    })
    .catch(err => console.error(err.response))
  }

  renderContent = () => {
    if(this.state.initialLoad === 'pending') {
      return (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1
        }}>
          <Spin indicator={antIcon} />
        </div>
      )
    } else {
      return (
        <Contents 
          goToChapter={this.goToChapter}
          loading={this.state.loading}
          deleteChapter={this.deleteChapter}
          segments={this.state.segments}
          addChapterModalOpen={this.addChapterModalOpen}
          addWorkspaceModalOpen={this.addWorkspaceModalOpen}
        />
      )
    }
  }

  render() {
    return (
      <Styles.WorkspaceWrapper>
        <Navbar />
        {this.renderContent()}
        <NewFormModal 
          modalOpen={this.state.modalOpen} 
          rank={this.state.newRank}
          appId={this.state.app._id}
          fetchChapterList={this.fetchChapterList}
          selectedWorkspace={this.state.selectedWorkspace}
          addChapterModalClose={this.addChapterModalClose}/>
        <NewWorkspaceModal
          modalOpen={this.state.workspaceModalOpen}
          rank={this.state.workspaceNewRank}
          appId={this.state.app._id}
          fetchChapterList={this.fetchChapterList}
          workspaceRank={this.state.workspaceRank}
          addWorkspaceModalClose={this.addWorkspaceModalClose} />
      </Styles.WorkspaceWrapper>
    )
  }
}

export default Workspace; 