import React from 'react';
import axios from 'axios';
import cloneDeep from "lodash.clonedeep"

import ContentEditorPresentational from '../Presentationals/ContentEditorPresentational';

class HomeContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      chapters: [],
      segments: [],
      selectedChapterIndex: null, // TODO: change this back to null
      selectedSegment: null, 
      app: {}
    }
  }

  componentDidMount(){
    this.fetchChapterList();
  }

  fetchChapterList = () => {
    axios
      .get(`/app/${this.props.match.params.appId}`)
      .then(resp => {
        let { chapters, segments } = resp.data;
        if (!chapters) chapters = [];
        if (!segments) segments = [];
        chapters.forEach(chapter => {
          let segment = segments.find(item => {
            return item._id === chapter.segment
          });
          if(segment){
            if(!segment.chapters){
              segment.chapters = [chapter]
            } else {
              segment.chapters.push(chapter)
            }
          }
        })
        if(resp.data.app){
          let selectedChapterIndex = null
          if(chapters.length){
            selectedChapterIndex = 0
          }
          this.setState({ 
            chapters,
            segments,
            selectedSegment: segments[0]._id,
            selectedChapterIndex,
            app: resp.data.app
          });
        }
      })
      .catch(err => {
        console.error(err.response)
      })
  }

  toggleSegmentPublish = (segmentId) => {
    let previousSegments = cloneDeep(this.state.segments);
    let toggledSegment = previousSegments.find(element => element._id === segmentId);
    let isPublished = !toggledSegment.isPublished
    toggledSegment.isPublished = isPublished;
    this.setState({
      patchButtonColor: 'pending',
      segments: previousSegments
    })
    axios
      .patch(`/segment/${segmentId}`, {
        isPublished
      })
      .then(resp => {
        this.setState({
          // patchButtonColor: "resolved"
        })
      })
      .catch(err => {
        toggledSegment.isPublished = !isPublished;
        this.setState({
          segments: previousSegments
        })
      })
  }

  selectChapter = (segment, index) => {
    this.setState({ 
      selectedSegment: segment,
      selectedChapterIndex: index
    })
  }

  deleteChapter = (id) => {
    axios.delete(`/chapter/${id}`)
    .then(resp => {
      this.fetchChapterList()
    })
    .catch(err => console.error(err.response))
  }

  deleteSegment = (id) => {
    axios.delete(`/segment/${id}`)
    .then(resp => {
      this.fetchChapterList()
    })
    .catch(err => console.error(err.response))
    
  }

  addNewChapter = index => {
    
  }

  render() {
    let selectedChapter = null;
    if(this.state.selectedSegment && typeof(this.state.selectedChapterIndex) == "number") {
      let segment = this.state.segments.find(segment => segment._id === this.state.selectedSegment);
      selectedChapter=segment.chapters[this.state.selectedChapterIndex];
    }
    return (
      <ContentEditorPresentational 
        toggleSegmentPublish={this.toggleSegmentPublish}
        selectChapter={this.selectChapter}
        selectedSegment={this.state.selectedSegment}
        appId={this.props.match.params.appId}
        fetchChapterList={this.fetchChapterList}
        selectedChapterIndex={this.state.selectedChapterIndex}
        chapters={null}
        app={this.state.app}
        segments={this.state.segments}
        selectedChapter={selectedChapter}
        deleteChapter={this.deleteChapter}
        deleteSegment={this.deleteSegment}
      />
    );
  }
}

export default HomeContainer;
