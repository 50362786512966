import React from "react";
import { Form, FormGroup } from "reactstrap";
import { Popover, Button, Select, Row, Col as AntdCol, Typography, Menu, Dropdown, Tooltip, Checkbox } from "antd";
import { EllipsisOutlined, InfoCircleOutlined, WarningTwoTone } from "@ant-design/icons";
import TextareaAutosize from 'react-textarea-autosize';

import * as Styles from "./NotesBuilderStyled";
import * as Formed from "../../../components/StyledItems/Form";
import ButtonWithState from "../../../components/ButtonWithState";

const { Option } = Select;
const { Title } = Typography;

const content = (
  <div style={{ maxWidth: "250px" }}>
    <p>A tip is shown once the MCQ is answered. This is to help the student to understand the concept clearly irrespective of if he got the answer right or not.</p>
  </div>
);

const hintContent = (
  <div style={{ maxWidth: "250px" }}>
    <p>
      A hint is used to help the student reach the answer. This can be helpful if there was some previous concept which was taught and needs to be used again.
    </p>
    <p>To add a hint, you have to first add a note to the lesson.</p>
  </div>
);

const menu = (props) => {
  return (
    <Menu>
      <Menu.Item onClick={() => props.toggleTip()}>
        <Popover content={content} title="Tip" placement="left">
          {props.addTip ? "Remove" : "Add"} Tip
        </Popover>
      </Menu.Item>
      <Menu.Item onClick={() => props.toggleHint()}>
      <Popover content={hintContent} title="Hint" placement="left">
        {props.addHint ? "Remove" : "Add"} Hint
      </Popover>
      </Menu.Item>
    </Menu>
  )
};

const McqBuilderPresentational = (props) => {
  let isMCQ = props.particle.type === "MCQ";
  let notes = props.particles.reduce((accumuUbuntur, particle) => {
    return particle.type === "NOTE"
      ? accumuUbuntur.concat(particle)
      : accumuUbuntur;
  }, []);
  return (
    <Styles.NotesWrapper>
      <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
        {isMCQ ? (
          <Formed.ParticleHeading>Multiple Choice Question</Formed.ParticleHeading>
        ) : <Formed.ParticleHeading>Poll</Formed.ParticleHeading>}
        {isMCQ ? (
          <React.Fragment>
            {props.options.find(option => option.isAnswer) ? (
              null
            ) : (
            <Tooltip title="You haven't marked an answer for this question">
              <WarningTwoTone style={{fontSize: '24px'}} twoToneColor="#ff7875"/>
            </Tooltip>
            )}
          </React.Fragment>
        ) : null }
      </div>
      <Form>
        <TextareaAutosize 
          style={{
            border: 0,
            outline: 0,
            fontSize: '18px',
            width: '100%',
            backgroundColor: 'transparent',
            fontFamily: 'Ubuntu, sans-serif'
          }}
          rows="1"
          type="textarea"
          name="question"
          value={props.question}
          onChange={props.onChangeNotes}
          placeholder="type your question here"
        />
        {/* <Formed.MultilineInput
          rows="1"
          type="textarea"
          name="question"
          id="question"
          value={props.question}
          onChange={props.onChangeNotes}
          placeholder="enter question"
        /> */}
          {props.options.map((option, index) => {
            return (
              <AntdCol key={`option-${props.particleId}-${index}`}>
                <AntdCol>
                  <Row>
                    <AntdCol
                      span={1}
                      style={{ display: 'flex', justifyContent: 'center'}}
                    >{`-`}</AntdCol>
                    <AntdCol span={20}>
                      <Formed.OrdinaryInput
                        type="text"
                        name={`option-${props.particleId}-${index}`}
                        id={`option-${props.particleId}-${index}`}
                        value={option.text}
                        onChange={(e) => props.onChangeOptions(e, index)}
                        onKeyDown={(e) => props.keyPressHandler(e, index)}
                        placeholder="option"
                      />
                    </AntdCol>
                    {/* todo: MTS need to add this later for quiz */}
                    {isMCQ ? (
                      <AntdCol span={1}>
                        <Checkbox
                          checked={option.isAnswer}
                          onChange={(e) => {
                            props.markAsAnswer(e, index);
                          }}
                        />
                      </AntdCol>
                    ) : null}
                  </Row>
                </AntdCol>
              </AntdCol>
            );
          })}
          {props.addTip ? (
            <React.Fragment>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <Title level={4} style={{marginTop: '10px'}}>Tip:</Title>
                <Popover content={content} title="Hint" placement="right">
                  <InfoCircleOutlined style={{paddingLeft: '10px'}}/>
                </Popover>
              </div>
              <TextareaAutosize 
                style={{
                  border: 0,
                  outline: 0,
                  fontSize: '18px',
                  width: '100%',
                  backgroundColor: 'transparent',
                  fontFamily: 'Ubuntu, sans-serif'
                }}
                rows="1"
                type="textarea"
                name="tip"
                value={props.tip}
                onChange={props.onChangeNotes}
                placeholder="enter tip"
              />
            </React.Fragment>
          ) : (
            null
          )}
          {props.addHint ? (
            <React.Fragment>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <Title level={4} style={{marginTop: '10px'}}>Hint:</Title>
                <Popover content={hintContent} title="Hint" placement="right">
                  <InfoCircleOutlined style={{paddingLeft: '10px'}}/>
                </Popover>
              </div>
              <AntdCol span={18}>
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  onChange={(value) => {
                    props.onChangeNotesAntd("hint", value);
                  }}
                  defaultValue={props.hint}
                >
                  {notes.map((note) => (
                    <Option value={note._id} key={note._id}>{note.title}</Option>
                  ))}
                </Select>
              </AntdCol>
              <div style={{marginBottom: "20px"}}></div>
            </React.Fragment>
          ) : (
            null
          )}

      </Form>
      <Styles.BottomButtonWrapper>
        <FormGroup style={{ paddingRight: "15px" }}>
          <Button danger type="primary" onClick={props.deleteNote}>
            {isMCQ ? "Delete MCQ" : "Delete Poll"}
          </Button>
        </FormGroup>
        <FormGroup>
          <ButtonWithState
            color="primary"
            text="Save"
            successText="saved"
            failureText="failed"
            state={props.networkState}
            onClick={props.saveNotes}
          />
        </FormGroup>
        {/* <Dropdown overlay={() => menu(props)} placement="bottomLeft" arrow trigger={['click']}>
          <Button style={{marginLeft: "auto"}} type="primary">
            <EllipsisOutlined style={{height: "25px",fontSize: "25px", marginLeft: "auto"}}/>
          </Button>
        </Dropdown> */}
      </Styles.BottomButtonWrapper>
    </Styles.NotesWrapper>
  );
};

export default McqBuilderPresentational;
