import React from "react";
import { Table } from 'antd';
import axios from "axios";
import cloneDeep from "lodash.clonedeep"

import Form from "./Form"
import Sidebar from "./Sidebar";

import * as Styles from "./Item.style"
import * as FormStyles from '../../components/StyledItems/Form'

const columns = [
  {
    title: () => <Styles.TableHeader>Title</Styles.TableHeader>,
    dataIndex: 'title',
    key: '_id',
    width: "40%",
    render: text => <Styles.TableText>{text}</Styles.TableText>
  },
  {
    title: () => <Styles.TableHeader>Multicore Score</Styles.TableHeader>,
    dataIndex: 'multicoreScore',
    key: '_id',
    width: "30%",
    render: text => <Styles.TableText>{text}</Styles.TableText>
  },
  {
    title: () => <Styles.TableHeader>Description</Styles.TableHeader>,
    dataIndex: 'description',
    key: '_id',
    width: "30%",
    render: text => <Styles.TableText>{text}</Styles.TableText>
  }
]

class Item extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemTypes: [],
      selectedTypeId: "",
      items: [],
      newItem: {},
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: false
      },
      loadingItems: false,
      currentEditingItem: {
        title: ""
      }
    }
  }

  componentDidMount() {
    axios
      .get('/itemtype')
      .then(resp => {
        this.setState({
          itemTypes: resp.data.itemTypes
        })
      })
      .catch(err => { console.error(err) })
  }

  onChangeItemType = (value) => {
    this.setState({
      selectedTypeId: value,
      pagination:{
        ...this.state.pagination,
        current: 1,
        pageSize: 10,
        total: 10
      },
      currentEditingItem: {
        title: ""
      }
    })
    this.getItems(value);
  }

  onChangeItemValue = (key, value) => {
    this.setState({
      currentEditingItem: {
        ...this.state.currentEditingItem,
        [key]: value
      }
    })
  }

  onSaveItem = () => {
    let item = this.state.currentEditingItem;
    if(!item._id){
      item.itemTypeId = this.state.selectedTypeId;
      axios
        .post(`/item`, item)
        .then(resp => {
          this.setState({
            currentEditingItem: {
              ...this.state.currentEditingItem,
              _id: resp.data.newItem._id
            }
          })
          // let pagination = cloneDeep(this.state.pagination)

          // this.setState({
          //   pagination: {
          //     ...pagination,
          //     current: 123
          //   }
          // })
          let x = this.state.pagination.total/this.state.pagination.pageSize
          let page = 0;
          if(x - Math.ceil(x) === 0){
            page = x + 1;
          } else {
            page = Math.ceil(x)
          }
          this.handleTableChange(page)
        })
        .catch(err => {
          console.error(err)
        })
    } else {
      axios
        .patch(`/item/${item._id}`, item)
        .then(resp => {

        })
        .catch(err => {
          console.error(err)
        })
    }
  }

  getItems = (selectedTypeId) => {
    if(selectedTypeId){
      axios
      .get(`/item?type=${selectedTypeId}&page=1`)
      .then(resp => {
        this.setState({
          items: resp.data.items,
          pagination: {
            ...this.state.pagination,
            total: resp.data.count
          }
        })
      })
      .catch(err => { console.error(err) })
    }
  }

  handleTableChange = (page) => {
    this.setState({ loadingItems: true });
    axios
      .get(`/item?type=${this.state.selectedTypeId}&page=${page}`)
      .then(resp => {
        let pagination = cloneDeep(this.state.pagination)
        this.setState({
          items: resp.data.items,
          pagination: {
            ...pagination,
            total: resp.data.count,
            current: page
          },
          loadingItems: false
        })
      })
      .catch(err => { console.error(err) })
  }

  searchItems = (e) => {
    if(!this.state.selectedTypeId) return null;
    if(!e.target.value){
      this.getItems(this.state.selectedTypeId)
      return;
    }
    let api = `/item/search/?searchTerm=${e.target.value}&itemType=${this.state.selectedTypeId}`
    axios
      .get(api)
      .then(resp => {
        this.setState({
          items: resp.data.item,
          pagination: {
            ...this.state.pagination,
            total: 10,
            current: 1
          }
        })
      })
      .catch(err => {
        console.error(err)
      })
  }

  createNewItem = () => {
    this.setState({
      currentEditingItem: {
        title: ""
      }
    })
  }

  render() {
    return (
      <Styles.ItemWrapper>
        <Sidebar 
          itemTypes={this.state.itemTypes}
          onChangeItemType={this.onChangeItemType}
          selectedTypeId={this.state.selectedTypeId}
        />
        <Styles.MiddleSection>
          <div style={{margin: '20px', display: 'flex'}}>
            <FormStyles.SubHeadingInput 
              placeholder="Search"
              onChange={this.searchItems}
            />
            <Styles.ClearButton onClick={this.createNewItem}>
              New Item
            </Styles.ClearButton>
          </div>
          <Table 
            style={{width: '100%'}}
            rowKey={record => record._id}
            columns={columns}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => {
                  this.setState({
                    currentEditingItem: record
                  })
                }
              }
            }}
            pagination={this.state.pagination}
            loading={this.state.loadingItems}
            onChange={(e) => this.handleTableChange(e.current)}
            dataSource={this.state.items} />
        </Styles.MiddleSection>
        <Form 
          onSaveItem={this.onSaveItem}
          itemType={this.state.itemTypes?.find(item => item._id === this.state.selectedTypeId)}
          currentEditingItem={this.state.currentEditingItem}
          onChangeItemValue={this.onChangeItemValue}
        />
      </Styles.ItemWrapper>
    )
  }
};

export default Item;