import React from 'react';
import axios from 'axios'
import cloneDeep from "lodash.clonedeep"

import NotesBuilderPresentational from "../Presentationals/NotesBuilderPresentational"
import McqBuilderPresentational from "../Presentationals/McqBuilderPresentational"
import MeaningBuilderPresentational from "../Presentationals/MeaningBuilderPresentational"
import ConvesionBuilderPresentational from "../Presentationals/ConversationBuilderPresentational"
import StackedNotesBuilderPresentational from "../Presentationals/StackedNotesBuilderPresentational";
import TextBuilderPresentational from "../Presentationals/TextBuilderPresentational"

class NotesBuilderContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      particleId: null,
      networkState: 'idle',
      addTip: false,
      addHint: false,
      hint: ''
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if(nextProps.particle._id !== prevState.particleId){
      return ({
        particleId: nextProps.particle._id,
        title: nextProps.particle.title ?  nextProps.particle.title: "",
        text: nextProps.particle.text ? nextProps.particle.text : "",
        question: nextProps.particle.question ? nextProps.particle.question : "",
        type: nextProps.particle.type,
        conversation: nextProps.particle.conversation,
        options: nextProps.particle.options && nextProps.particle.options.length ? nextProps.particle.options : [{ text: "" }],
        tip: nextProps.particle.tip ? nextProps.particle.tip: "",
        addTip: nextProps.particle.tip ? true : false,
        addHint: nextProps.particle.hint ? true : false,
        answer: nextProps.particle.answer ? nextProps.particle.answer : "",
        stackedNotes: nextProps.particle.stackedNotes,
        hint: nextProps.particle.hint ? nextProps.particle.hint : '' 
      })
    }
    return null;
  }

  saveNotes = () => {
    this.setState({
      networkState: 'pending'
    })
    axios
      .patch(`/particle/${this.state.particleId}`, {
        ...this.state
      })
      .then(resp => {
        this.setState({
          message: "saved",
          particleSaved: true,
          networkState: 'resolved'
        })
        this.props.getLessonData(this.props.selectedChapterId)
      })
      .catch(err => {
        this.setState({
          message: "not saved something happened",
          particleSaved: false,
          networkState: 'rejected'
        })
      })
  }

  deleteNote = () => {
    this.props.deleteParticle(this.state.particleId)
  }

  onChangeNotesAntd = (name, value) => {
    if(this.state.setTimeOutId){
      clearTimeout(this.state.setTimeOutId)
    }
    this.setState({
      [name]: value,
      message: "",
      particleSaved: false,
      networkState: 'waiting',
      setTimeOutId:setTimeout(() => {this.saveNotes()}, 1000)
    })
  }

  onChangeNotes = e => {
    if(this.state.setTimeOutId){
      clearTimeout(this.state.setTimeOutId)
    }
    this.setState({
      [e.target.name]: e.target.value,
      message: "",
      particleSaved: false,
      networkState: 'waiting',
      setTimeOutId:setTimeout(() => {this.saveNotes()}, 1000)
    })
  }

  onChangeOptions = (e, index) => {
    if(this.state.setTimeOutId){
      clearTimeout(this.state.setTimeOutId)
    }
    let options = cloneDeep(this.state.options)
    options[index].text = e.target.value
    this.setState({
      options,
      particleSaved: false,
      networkState: 'waiting',
      setTimeOutId:setTimeout(() => {this.saveNotes()}, 1000)
    })
  }

  onChangeStackedNote = (e, index) => {
    if(this.state.setTimeOutId){
      clearTimeout(this.state.setTimeOutId)
    }
    let stackedNotes = cloneDeep(this.state.stackedNotes)
    stackedNotes[index].text = e.target.value
    this.setState({
      stackedNotes,
      particleSaved: false,
      networkState: 'waiting',
      setTimeOutId:setTimeout(() => {this.saveNotes()}, 1000)
    })
  }

  markAsAnswer = (e, index) => {
    if(this.state.setTimeOutId){
      clearTimeout(this.state.setTimeOutId)
    }
    let options = cloneDeep(this.state.options)
    options[index].isAnswer = e.target.checked
    this.setState({
      options,
      particleSaved: false,
      networkState: 'waiting',
      setTimeOutId:setTimeout(() => {this.saveNotes()}, 1000)
    })
  }

  deleteStackedNote = index => {
    if(this.state.setTimeOutId){
      clearTimeout(this.state.setTimeOutId)
    }
    let stackedNotes = cloneDeep(this.state.stackedNotes)
    stackedNotes.splice(index, 1)
    this.setState({
      stackedNotes,
      particleSaved: false,
      networkState: 'waiting',
      setTimeOutId:setTimeout(() => {this.saveNotes()}, 1000)
    })
  }

  toggleTip = () => {
    let newStateObject = {}
    if(this.state.addTip){
      newStateObject.tip = ""
    }
    this.setState({
      addTip: !this.state.addTip, 
      particleSaved: false,
      networkState: 'waiting',
      setTimeOutId:setTimeout(() => {this.saveNotes()}, 1000),
      ...newStateObject
    })
  }

  toggleHint = () => {
    let newStateObject = {}
    if(this.state.addHint){
      newStateObject.hint = ""
    }
    this.setState({
      addHint: !this.state.addHint,
      particleSaved: false,
      networkState: 'waiting',
      setTimeOutId:setTimeout(() => {this.saveNotes()}, 1000),
      ...newStateObject
    })
  }

  deleteOption = index => {
    if(this.state.setTimeOutId){
      clearTimeout(this.state.setTimeOutId)
    }
    let options = cloneDeep(this.state.options)
    options.splice(index, 1)
    this.setState({
      options,
      particleSaved: false,
      networkState: 'waiting',
      setTimeOutId:setTimeout(() => {this.saveNotes()}, 1000)
    })
  }

  onChangeConversation = (e, index) => {
    if(this.state.setTimeOutId){
      clearTimeout(this.state.setTimeOutId)
    }
    let conversation = cloneDeep(this.state.conversation);
    conversation[index][e.target.name] = e.target.value;
    this.setState({
      conversation,
      particleSaved: false,
      networkState: 'waiting',
      setTimeOutId:setTimeout(() => {this.saveNotes()}, 1000)
    })
  }

  addConversation = () =>{
    let conversation = cloneDeep(this.state.conversation);
    conversation.push({
      name: "",
      sentence: ""
    })
    this.setState({
      conversation
    })
  }

  keyPressHandler = (e, index) => {
    if(e.key === 'Enter') {
      let options = cloneDeep(this.state.options)
      options.splice(index+1, 0, { text: ""})
      this.setState({
        options
      }, () => {
        document.getElementById(`option-${this.state.particleId}-${index+1}`).focus();
        document.getElementById(`option-${this.state.particleId}-${index+1}`).select();
      })
    } else if (e.key === 'Backspace'){
      let currentIndexText = this.state.options[index].text;
      if(!currentIndexText){
        if(index === 0){
          return;
        }
        e.preventDefault();
        let options = cloneDeep(this.state.options);
        options.splice(index, 1);
        this.setState({
          options
        }, () => {
          if(index-1 >= 0){
            document.getElementById(`option-${this.state.particleId}-${index-1}`).focus();  
          }
        })
      }
    } else if (e.key === 'ArrowUp'){
      if(index-1 >= 0){
        document.getElementById(`option-${this.state.particleId}-${index-1}`).focus();  
      }
    } else if (e.key === 'ArrowDown'){
      let element = document.getElementById(`option-${this.state.particleId}-${index+1}`);
      if(element) element.focus();  
      
    }
  }

  addOption = (nextOptionId) => {
    let options = cloneDeep(this.state.options)
    options.push({ text: ""})
    this.setState({
      options
    }, () => {
      // we were adding this
      document.getElementById(nextOptionId).focus()
    })
  }

  addStackedNote = (nextStackedNoteId, type) => {
    let stackedNotes = cloneDeep(this.state.stackedNotes);
    stackedNotes.push({
      type,
    })
    this.setState({ stackedNotes });
  }

  render() {
    if(this.state.type === "MEANING" || this.state.type === "JUMBLEDWORDS"){
      return (
        <MeaningBuilderPresentational 
          particles={this.props.particle}
          question={this.state.question}
          answer={this.state.answer}
          onChangeNotes={this.onChangeNotes}
          deleteNote={this.deleteNote}
          saveNotes={this.saveNotes}
          message={this.state.message}
          networkState={this.state.networkState}
        />
      )
    } else if(this.state.type === "NOTE"){
      return (
        <NotesBuilderPresentational 
          particle={this.props.particle}
          title={this.state.title}
          text={this.state.text}
          saveNotes={this.saveNotes}
          deleteNote={this.deleteNote}
          message={this.state.message}
          onChangeNotes={this.onChangeNotes}
          networkState={this.state.networkState}
        />
      )
    } else if (this.state.type === "TEXT") {
      return (
        <TextBuilderPresentational 
          particle={this.props.particle}
          question={this.state.question}
          onChangeNotes={this.onChangeNotes}
          deleteNote={this.deleteNote}
          networkState={this.state.networkState}
          saveNotes={this.saveNotes}
        />
      )
    } else if(this.state.type === "CONVERSATION") {
      return (
        <ConvesionBuilderPresentational 
          onChangeNotes={this.onChangeNotes}
          title={this.state.title}
          deleteNote={this.deleteNote}
          saveNotes={this.saveNotes}
          addConversation={this.addConversation}
          message={this.state.message}
          conversation={this.state.conversation}
          onChangeConversation={this.onChangeConversation}
          networkState={this.state.networkState}
        />
      )
    } else if(this.state.type === "STACKEDNOTES"){
      return (
        <StackedNotesBuilderPresentational 
          stackedNotes={this.state.stackedNotes}
          addStackedNote={this.addStackedNote}
          onChangeStackedNote={this.onChangeStackedNote}
          particleId={this.state.particleId}
          keyPressHandler={this.keyPressHandler}
          deleteStackedNote={this.deleteStackedNote}
          deleteNote={this.deleteNote}
          networkState={this.state.networkState}
          saveNotes={this.saveNotes}
        />
      )
    } else {
      return (
        <McqBuilderPresentational
          particle={this.props.particle}
          onChangeOptions={this.onChangeOptions}
          particleId={this.state.particleId}
          onChangeNotes={this.onChangeNotes}
          question={this.state.question}
          options={this.state.options}
          addTip={this.state.addTip}
          addHint={this.state.addHint}
          onChangeNotesAntd={this.onChangeNotesAntd}
          hint={this.state.hint}
          toggleTip={this.toggleTip}
          toggleHint={this.toggleHint}
          keyPressHandler={this.keyPressHandler}
          tip={this.state.tip}
          saveNotes={this.saveNotes}
          deleteNote={this.deleteNote}
          particles={this.props.particles}
          deleteOption={this.deleteOption}
          message={this.state.message}
          addOption={this.addOption}
          markAsAnswer={this.markAsAnswer}
          networkState={this.state.networkState}
        />
      )
    }

  }
}

export default NotesBuilderContainer;