import React from 'react';
import { Modal, Button, Select } from 'antd';
import axios from "axios";

import * as Styles from "./NewFormModal.style"

const { Option } = Select;

class NewFormModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      formType: "",
      error: "",
    }
  }

  onChangeTitle = (title) => {
    this.setState({
      title,
      error: ""
    })
  }

  onChangeFormType = (type) => {
    this.setState({
      formType: type,
      error: ""
    })
  }

  onSubmit = () => {
    let { title, formType } = this.state;
    if(!title){
      this.setState({
        error: "Please give your form a name"
      })
      return;
    } else if(!formType) {
      this.setState({
        error: "Plesae select type of form"
      })
      return;
    }
    axios
      .post('/chapter', {
        title: title,
        app: this.props.appId,
        segment: this.props.selectedWorkspace,
        rank: this.props.rank,
        type: formType
      })
      .then(resp => {
        this.props.addChapterModalClose();
        this.props.fetchChapterList();
      })
      .catch(err => {
        this.setState({
          submitChapterState: "rejected",
          error: err.response.data.error,
          title: "",
          description: "",
          type: ""  
        })
      })
    

  }

  onCancel = () => {
    this.setState({
      title: "",
      formType: "",
      error: ""
    })
    this.props.addChapterModalClose()
  }

  render() {
    return (
      <>
        <Modal
          title="Build a new form" 
          visible={this.props.modalOpen} 
          onOk={this.onSubmit} 
          onCancel={this.onCancel}
        >
          <Styles.Question>Give a name to your form</Styles.Question>
          <Styles.Input 
            placeholder="Form name here"
            onChange={(e) => this.onChangeTitle(e.target.value)}
            value={this.state.title}>

          </Styles.Input>
          <Styles.Question>What are you building?</Styles.Question>
          <Styles.Description>
            <Styles.TextPara>You can build either <b>a quiz</b> or <b>a form</b>.</Styles.TextPara>
            <Styles.TextPara>A quiz can be used to ask multiple choice questions and show a score at the end.</Styles.TextPara>
            <Styles.TextPara>A form can be used to conduct polls or to get text answers for better understanding of your audience.</Styles.TextPara>
          </Styles.Description>
          <Select 
            placeholder="Select type"
            bordered={false}
            onChange={this.onChangeFormType}
            style={{width: '50%'}}>
            <Option value="QUIZ">Quiz</Option>
            <Option value="FORM">Form</Option>
          </Select>
          <Styles.Error>{this.state.error}</Styles.Error>
        </Modal>
      </> 
    );
  }
}

export default NewFormModal;