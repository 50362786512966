import React from "react";
import { 
  Dropdown, 
  DropdownToggle, 
  DropdownMenu, 
  DropdownItem,
  Form,
  Input,
  FormGroup,
  InputGroupAddon,
  InputGroup,
  Button as ReactStrapButton
} from 'reactstrap';
import { InfoCircleOutlined, DeleteOutlined } from "@ant-design/icons"
import { Tooltip, Button, Popover, Popconfirm } from 'antd'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import HowToAddFromGSheets from "./HowToAddFromGSheets"
import { BlockPicker } from 'react-color';

import mcq from "./mcq.jpg"
import notes from "./notes.jpg"

import note from "./note.png";
import quiz from './quiz.png';
import textInput from './text-input.png';

import NotesBuilder from "../../NotesBuilder"
import * as Styles from "./ParticleBuilderStyled"
import * as Formed from "../../../components/StyledItems/Form"

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 1,
  // margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "transparent",
  borderRadius: '10px',
  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "transparent",
  padding: grid,
  borderRadius: '10px'
});


class ParticleBuilderPresentational extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  onDragEnd = (result) => {
    if(result.source && result.destination){
      this.props.reorderParticles(result.source.index, result.destination.index)
    }
  }

  render() {
    let { props } = this;
    return (
      <Styles.ParticleWrapper>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Form style={{paddingLeft: '10px', maxWidth: '600px'}}>
            <FormGroup>
              <Formed.HeadingInput 
                type="text" 
                name="title" 
                value={props.title}
                onChange={(e) => props.onChangeChapterDetails(e.target.name, e.target.value)}
                placeholder="enter chapter title" 
              />
            </FormGroup>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Styles.TextColorHeading>
                Theme
              </Styles.TextColorHeading>
              <div style={{paddingLeft: '10px'}}>
                <Tooltip 
                  placement="right"
                  title="The color selected here will be the color of the text and the overall theme in the quiz">
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
            </div>
            <BlockPicker 
              onChangeComplete={(value) => {
                props.onChangeChapterDetails('textColor', value.hex)
              }}
              triangle="hide"
              color={this.props.textColor} />
          </Form>
          <div style={{maxWidth: '620px'}}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {props.particles.map((particle, index) => {
                    return (
                      (
                        <Draggable key={particle._id} draggableId={particle._id} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <NotesBuilder 
                                key={particle._id}
                                particle={particle}
                                selectedChapterId={props.selectedChapterId}
                                getLessonData={props.getLessonData}
                                particles={props.particles}
                                deleteParticle={props.deleteParticle}
                              /> 
                              {/* <div>{particle.rank}</div> */}
                            </div>
                          )}
                        </Draggable>
                      ) 
                    )
                  }
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
          <div style={{paddingLeft: '20px'}}>
            {props.chapterType === "QUIZ" ? (
              <Dropdown isOpen={props.dropdownOpen} toggle={props.toggleDropdown}>
                <DropdownToggle caret>
                  Add new
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header>Select type to add</DropdownItem>
                  <React.Fragment>
                    <Popover placement="right" title={"Multiple Choice Question"} content={quizContent}>
                      <DropdownItem onClick={() => {props.addNewParticle("MCQ")}}>Multiple Choice Question</DropdownItem>
                    </Popover>
                    <Popover placement="right" title={"Note"} content={noteContent}>
                      <DropdownItem onClick={() => {props.addNewParticle("NOTE")}}>Notes</DropdownItem>
                    </Popover>
                  </React.Fragment>
                </DropdownMenu>
              </Dropdown>
            ) : (
              <Dropdown isOpen={props.dropdownOpen} toggle={props.toggleDropdown}>
                <DropdownToggle caret>
                  Add new
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header>Select type to add</DropdownItem>
                  <React.Fragment>
                    <Popover placement="right" title={"Poll"} content={pollContent}>
                      <DropdownItem onClick={() => {props.addNewParticle("POLL")}}>Poll</DropdownItem>
                    </Popover>
                    <Popover placement="right" title={"Note"} content={noteContent}>
                      <DropdownItem onClick={() => {props.addNewParticle("NOTE")}}>Notes</DropdownItem>
                    </Popover>
                    <Popover placement="right" title={"Text Input"} content={textInputContent}>
                      <DropdownItem onClick={() => {props.addNewParticle("TEXT")}}>Text Input</DropdownItem>
                    </Popover>
                    {/* <DropdownItem onClick={() => {props.addNewParticle("MEANING")}}>Meaning</DropdownItem>
                    <DropdownItem onClick={() => {props.addNewParticle("CONVERSATION")}}>Conversation</DropdownItem>
                    <DropdownItem onClick={() => {props.addNewParticle("JUMBLEDWORDS")}}>Jumbled Words</DropdownItem>
                    <DropdownItem onClick={() => {props.addNewParticle("STACKEDNOTES")}}>Stack of Notes</DropdownItem> */}
                  </React.Fragment>
                </DropdownMenu>
              </Dropdown>
            )}
          </div>
        </DragDropContext>
      </Styles.ParticleWrapper>
    ) 
  }
}

const mcqContent = (
  <div style={{maxWidth: '220px'}}>
    <img src={note} alt="mcq" />
    <p>Questions which can be used to learn. Has tips and hints to assist in the learning process.</p>
  </div>
);

const noteContent = (
  <div style={{maxWidth: '420px'}}>
    <img src={note} alt="mcq" style={{maxWidth: '420px'}}/>
    <p>Notes can be used for telling the user something such as details on how to fill the next form or the answer in case of a quiz.</p>
  </div>
);

const pollContent = (
  <div style={{maxWidth: '420px'}}>
    <img src={quiz} alt="mcq" style={{maxWidth: '420px'}}/>
    <p>Questions which can be used to take a poll to gather details</p>
  </div>
);

const quizContent = (
  <div style={{maxWidth: '420px'}}>
    <img src={quiz} alt="mcq" style={{maxWidth: '420px'}}/>
    <p>Multiple choice questions which can be used to ask questions at the end of which we can show the results.</p>
  </div>
);

const textInputContent = (
  <div style={{maxWidth: '420px'}}>
    <img src={textInput} alt="mcq" style={{maxWidth: '420px'}}/>
    <p>Text Input can be used if you want to collect text data such as email, phone number etc.</p>
  </div>
);

const notesContent = (
  <div style={{maxWidth: '220px'}}>
    <img src={notes} alt="notes" />
    <p>Notes are to give details about a particular topic and explain it.</p>
  </div>
);

export default ParticleBuilderPresentational;