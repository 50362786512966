import React, { useState } from "react";
import {
  Switch,
  Route,
  Link,
  NavLink,
  withRouter,
  useRouteMatch
} from "react-router-dom";
import {
  Navbar,
  NavbarBrand,
  Collapse,
  Nav,
  NavItem,
  NavbarToggler
} from "reactstrap"
import { ArrowLeftOutlined } from '@ant-design/icons'

import * as Styles from "./Form.style";

import FormBuilder from "../FormBuilder"
import Responses from "../Responses"
import Share from "../Share";
import Preview from "../Preview"

const Form = (props) => {

  let { path, url } = useRouteMatch();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <Styles.PageWrapper>
      <Navbar color="#f5f6f8" light expand="md">
        <NavbarBrand href="/">
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{padding: '0px 10px', cursor: 'pointer', display: 'flex'}} onClick={() => {props.history.push('/')}}>
              <ArrowLeftOutlined />
            </div>
            <Styles.Heading>
              makethemstay
            </Styles.Heading>
          </div>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <div style={{display: 'flex', justifyContent: 'center', flexGrow: 1}}>
          <Nav navbar>
          <Styles.IndividualLink>
            <NavLink exact activeClassName="on-selected" to={`${url}`}>Editor</NavLink>
          </Styles.IndividualLink>
          <Styles.IndividualLink>
            <NavLink activeClassName="on-selected" to={`${url}/results`}>Results</NavLink>
          </Styles.IndividualLink>
          <Styles.IndividualLink>
            <NavLink activeClassName="on-selected" to={`${url}/share`}>Share</NavLink>
          </Styles.IndividualLink>
          <Styles.IndividualLink>
            <NavLink activeClassName="on-selected" to={`${url}/preview`}>Preview</NavLink>
          </Styles.IndividualLink>
          </Nav>
          </div>
        </Collapse>
        {/* <NavbarText> */}
        <div style={{visibility: 'hidden', display: window.matchMedia("(max-width: 700px)").matches ? 'none' : 'inherit'}}>
          <NavbarBrand href="/">
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{padding: '0px 10px', cursor: 'pointer', display: 'flex'}} onClick={() => {props.history.push('/')}}>
                <ArrowLeftOutlined />
              </div>
              <Styles.Heading>
                makethemstay
              </Styles.Heading>
            </div>
          </NavbarBrand>
        </div>
        {/* </NavbarText> */}
      </Navbar>      
      <Switch>
        <Route exact path={path}>
          <FormBuilder />
        </Route>
        <Route path={`${path}/results`}>
          <Responses />
        </Route>
        <Route path={`${path}/share`}>
          <Share />
        </Route>
        <Route path={`${path}/preview`}>
          <Preview />
        </Route>
      </Switch>
    </Styles.PageWrapper>
  )
}

export default withRouter(Form);
