import styled from "styled-components"

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  padding-bottom: 30px;
`

export const CodeWrapper = styled.div`
  background-color: black;
  padding: 15px;
  width: 68%;
  max-width: 900px;
`;

export const Heading = styled.div`
  font-family: Ubuntu, sans-serif;
  font-size: 1.4rem;
  font-weight: 800;
  padding: 15px;
`

export const Content = styled.div`
  font-family: Ubuntu, sans-serif;
  padding: 15px;
  max-width: 900px;
`;

export const InstalledImage = styled.img`

`