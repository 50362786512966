import styled from "styled-components";

export const BarWrapper = styled.div`
  width: 320px;
  height: 100%;
  overflow: hidden;
  padding: 80px 0px 20px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /* border: 2px solid #292929; */
`;

export const Heading = styled.div`
  font-size: 32px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  margin: 50px 0px 30px 5px;
  box-sizing: border-box;
`;

export const IndividualSegmentTitle = styled.input`
  border: 0;
  outline: 0;
  background-color: transparent;
  font-size: 18px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  color: ${props => {
    if(props.networkState === "PENDING"){
      return "orange"
    } else if (props.networkState === "ERROR"){
      return "red"
    } else {
      return "black"
    }
  }};
`

export const Circle = styled.div`
  width: 25px;
  height: 25px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 900;
  position: absolute;
  z-index: 5;
  left: 12.5px;
  top: -12.5px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IndividualSegmentWrapper = styled.div`
  position: relative;
  /* &:nth-child(even) {
    background-color: #C4C4C4;
  }
  &:nth-child(odd) {
    background-color: white;
  } */
  background-color: #fff;
  padding: 0px;
  margin-bottom: 10px;
  /* cursor: pointer; */
  border-radius: 10px 10px 10px 10px;
`

export const Button = styled.div`
  width: 80%;
  background-color: #292929;
  color: white;
  box-sizing: border-box;
  padding: 15px 25px;
  align-self: center;
  display: flex;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  text-align: center;
  justify-content: center;
  border-radius: 10px;
  margin-top: 25px;
  cursor: pointer;
`;

export const ThinButton = styled.div`
  width: 80%;
  /* background-color: #292929; */
  border: 1px solid #292929;
  color: #292929;
  box-sizing: border-box;
  padding: 5px 15px;
  align-self: center;
  display: flex;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  text-align: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 25px;
  cursor: pointer;
`

export const SegmentListWrapper = styled.div`
  overflow: auto;
  flex-grow: 1;
`

export const ChapterListWrapper = styled.div`
  padding-left: 10px;
`;

export const IndividualChapter = styled.div`
  color: black;
  background-color: ${props => {
    if(props.selected){
      return "#f5f6f8"
    } else {
      return "transparent"
    }
  }};
  margin-bottom: 5px;
  font-family: 'Ubuntu', sans-serif;
  padding: 0px 5px;
  font-size: 18px;
  border-radius: 5px 0px 0px 5px;
  cursor: pointer;
`;

export const PlusButton = styled.div`
  font-size: 18px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  color: black;
  margin-bottom: 0px;
  padding: 5px;
  cursor: pointer;
  /* box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 2px 12px; */
`;

export const SegmentTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`