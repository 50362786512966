import React from 'react'
import axios from "axios"

import * as Styles from './Preview.style'

class Widget extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  componentDidMount() {

  }

  render() {
    let { props } = this;
    return (
      <Styles.PageWrapper>
        <iframe 
        width="100%"
        style={{border: 'none', maxWidth: '900px'}}
        height="450px"
        srcDoc={`<div class="mts-widget" id=${props.id} url="${props.baseUrl}/chapter/with-session/${props.id}" baseUrl="${props.baseUrl}"></div>
<script src="${props.baseUrl}/widget/index.js"></script>`} />
      </Styles.PageWrapper>
    )
  }
}

export default Widget