import styled from "styled-components";

export const NotesWrapper = styled.div`
  padding: 20px;
  /* margin-bottom: 20px; */
  border-radius: 10px;
  /* min-width:450px; */
  background-color: white;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px, rgb(0 0 0 / 4%) 0px 8px 14px, rgb(0 0 0 / 2%) 0px 12px 16px;
  &:hover {
  }
`;

export const BottomButtonWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction:row;
  /* justify-content:flex-end; */
`;

export const ParticleId = styled.div`
  
`