import React from "react";
import {
  withRouter,
} from "react-router-dom"; 
 import axios from "axios";

import * as Styles from "./Responses.style"
import { Spin } from "antd"
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;
class Insights extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalSessions: 0,
      startedSessions: 0,
      initialLoad: 'pending'
    }
  }

  componentDidMount() {
    axios.get(`/session/insights/?chapter=${this.props.match.params.chapterId}`)
      .then(resp => {
        this.setState({
          ...resp.data,
          initialLoad: 'completed'
        })
      })
  }

  render() {
    if(this.state.initialLoad === 'pending' ){
      return (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1
        }}>
          <Spin indicator={antIcon} />
        </div>
      )
    }

    return (
      <div>
        <Styles.Insights>
          <Styles.InsightWrapper>
          <Styles.InsightTitle>
            Views
          </Styles.InsightTitle>
          <Styles.InsightCount>
            {this.state.totalSessions}
          </Styles.InsightCount>
          </Styles.InsightWrapper>
          <Styles.InsightWrapper>

          <Styles.InsightTitle>
            Starts 
          </Styles.InsightTitle>
          <Styles.InsightCount>
            {this.state.startedSessions}
          </Styles.InsightCount>
          </Styles.InsightWrapper>
        </Styles.Insights>
      </div>
    )
  }
}

export default withRouter(Insights);