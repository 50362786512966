import styled from "styled-components";

export const Wrapper = styled.div`
  /* width: 240px; */
  /* background-color: pink; */
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`

export const CrazyWrapper = styled.div`
  /* height: 100%; */
  display: flex;
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  flex-shrink: 1;
`