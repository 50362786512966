import React from 'react';

import * as Styles from "./ContentEditorStyled"
import ChapterBar from "../../../components/ChapterBar"
import ParticleBuilder from "../../../components/ParticleBuilder"
import NavBar from "../../../components/NavBar";

const HomePresentational = (props) => {
  return (
    <Styles.Wrapper>
      <NavBar history={props.history}/>
      <Styles.CrazyWrapper>
        {/* <ChapterBar 
          fetchChapterList={props.fetchChapterList}
          selectChapter={props.selectChapter}
          selectedChapterIndex={props.selectedChapterIndex}
          appId={props.appId}
          selectedChapter={props.selectedChapter}
          toggleSegmentPublish={props.toggleSegmentPublish}
          segments={props.segments}
          app={props.app}
          deleteSegment={props.deleteSegment}
          chapters={props.chapters} /> */}
        <ParticleBuilder 
          selectedChapter={props.selectedChapter}
          deleteChapter={props.deleteChapter}
        />
      </Styles.CrazyWrapper>
      </Styles.Wrapper>
  );
};

export default HomePresentational;
