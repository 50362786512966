import React from 'react';
import { Form, FormGroup } from "reactstrap";
import { Button } from "antd"
import TextareaAutosize from 'react-textarea-autosize';

import * as Styles from "./NotesBuilderStyled";
import * as Formed from "../../../components/StyledItems/Form";
import ButtonWithState from "../../../components/ButtonWithState";

const TextBuilderPresentational = props => {
  return (
    <Styles.NotesWrapper>
      <Formed.ParticleHeading>Text Input</Formed.ParticleHeading>
      <Form>
        <TextareaAutosize 
          style={{
            border: 0,
            outline: 0,
            fontSize: '18px',
            width: '100%',
            backgroundColor: 'transparent',
            fontFamily: 'Ubuntu, sans-serif'
          }}
          rows="1"
          type="textarea"
          name="question"
          value={props.question}
          onChange={props.onChangeNotes}
          placeholder="type your question here"
        />
      </Form>
      <Styles.BottomButtonWrapper>
        <FormGroup style={{ paddingRight: "15px" }}>
          <Button danger type="primary" onClick={props.deleteNote}>
            Delete Text Input
          </Button>
        </FormGroup>
        <FormGroup>
          <ButtonWithState
            color="primary"
            text="Save"
            successText="saved"
            failureText="failed"
            state={props.networkState}
            onClick={props.saveNotes}
          />
        </FormGroup>
        {/* <Dropdown overlay={() => menu(props)} placement="bottomLeft" arrow trigger={['click']}>
          <Button style={{marginLeft: "auto"}} type="primary">
            <EllipsisOutlined style={{height: "25px",fontSize: "25px", marginLeft: "auto"}}/>
          </Button>
        </Dropdown> */}
      </Styles.BottomButtonWrapper>
    </Styles.NotesWrapper>
  )
};

export default TextBuilderPresentational;