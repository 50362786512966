import React from 'react';
import { Popover } from 'antd'
import { MoreOutlined } from "@ant-design/icons"
import * as Styles from "./Contents.style";
import { Menu, Dropdown } from 'antd';

const content = (type) => {
  if (type === "QUIZ") {
    return (
      <div style={{maxWidth: '240px'}}>
        Quiz is a collection of Multiple Choice Questions at the end of which we show the score.
      </div>
    )
  } else if (type === "FORM") {
    return (
      <div style={{maxWidth: '240px'}}>
        Form can have multiple types such as Multiple Choice Questions, 
        Text answers, Numbers and so on.
      </div>
    )
  }
}

const menu = (chapterId, deleteChapter) => {
  return (
    <Menu>
      <Menu.Item onClickCapture={(e) => { 
        e.stopPropagation();
        deleteChapter(chapterId);
       }}>
        Delete
      </Menu.Item>
    </Menu>
  )
};

const Contents = props => {
  return (
    <Styles.ContentWrapper>
      {props.segments.map(segment => {
        let segmentNewRank = segment.chapters?.[segment.chapters.length - 1].rank;
        if(!segmentNewRank) segmentNewRank = 100;
        segmentNewRank += 100;
        return (
          <Styles.SegmentWrapper key={segment._id}>
            <Styles.SegmentTitle>
              {segment.title}
            </Styles.SegmentTitle>
            <Styles.FormsWrapper>
              {segment.chapters?.map(chapter => (
                <Styles.FormIcon 
                  key={chapter._id}>
                  <Styles.FormIconTop onClick={() => props.goToChapter(chapter._id)}>
                    <Popover title={null} content={content(chapter.type)}>
                      <Styles.Type>
                        {chapter.type}
                      </Styles.Type>
                    </Popover>
                  </Styles.FormIconTop>
                  <Styles.FormIconBottom>
                    <Styles.FormTitle onClick={() => props.goToChapter(chapter._id)}>
                      {chapter.title}
                    </Styles.FormTitle>
                    <Styles.MoreButton>
                      <Dropdown 
                        overlay={(e) => menu(chapter._id, props.deleteChapter)} 
                        placement="bottomCenter"
                        onClick={e => e.stopPropagation()} 
                        trigger={['click']}>
                        <MoreOutlined />
                      </Dropdown>
                    </Styles.MoreButton>
                  </Styles.FormIconBottom>
                </Styles.FormIcon>
              ))}
              <Styles.FormIcon onClick={() => props.addChapterModalOpen(segment._id, segmentNewRank)}>
                <Styles.PlusIcon>
                  +
                </Styles.PlusIcon>
              </Styles.FormIcon>
            </Styles.FormsWrapper>
          </Styles.SegmentWrapper>
        )
      }
      )}
      <Styles.AddWorkspace onClick={() => {props.addWorkspaceModalOpen()}}>
        <Styles.AddWorkspaceButton>
          + Add Workspace
        </Styles.AddWorkspaceButton>
      </Styles.AddWorkspace>
    </Styles.ContentWrapper>
  )
};

export default Contents;