import React from "react";
import axios from "axios";

import ChapterBarPresentational from "../Presentationals/ChapterBarPresentational"

class ChapterBarContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      showAddLessonModal: false,
      showAddSegmentModal: false,
      showAddStudentModal: false,
      title:"",
      description:"",
      type:"",
      submitChapterState: "idle",
      submitSegmentState: "idle",
      currentSegment: "",
      currentSegmentIndex: null
    }
  }


  submitNewSegment = () => {
    this.setState({ submitSegmentState: "pending"});
    let rank = 100;
    let segmentLength = this.props.segments.length;
    if(segmentLength > 0){
      rank = this.props.segments[segmentLength-1].rank + 100;
    }

    let postData = {
      title: this.state.title,
      description: this.state.description,
      appId: this.props.appId,
      rank: rank,
      type: this.state.type?this.state.type: undefined
    }

    if(this.state.type){
      postData.type = this.state.type
    }
    axios
      .post('/segment', postData)
      .then(resp => {
        this.props.fetchChapterList();
        this.setState({ 
          showAddSegmentModal: false,
          submitSegmentState: "resolved",
          title: "",
          description: "",
          type: ""    
        })
      })
      .catch(err => {
        this.setState({
          submitSegmentState: "rejected",
          error: err.response.data.error,
        })
      })
  }

  submitNewChapter = () => {
    this.setState({ submitChapterState: "pending"});

    let rank = 100;
    let segment = this.props.segments[this.state.currentSegmentIndex];
    let chapters = segment.chapters;
    if(chapters && chapters.length){
      rank = chapters[chapters.length - 1].rank + 100
    }
    axios
      .post('/chapter', {
        title: this.state.title,
        description: this.state.description,
        app: this.props.appId,
        segment: this.state.currentSegment,
        rank: rank,
        type: this.state.type?this.state.type: undefined
      })
      .then(resp => {
        this.props.fetchChapterList();
        this.setState({ 
          showAddLessonModal: false,
          submitChapterState: "resolved",
          title: "",
          description: "",
          type: ""    
        })
      })
      .catch(err => {
        this.setState({
          submitChapterState: "rejected",
          error: err.response.data.error,
          title: "",
          description: "",
          type: ""  
        })
      })
  }

  formOnChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  toggleAddLessonModal = (segmentId, segmentIndex) => {
    this.setState({ 
      showAddLessonModal: !this.state.showAddLessonModal,
      submitChapterState: "idle",
      currentSegment: segmentId,
      currentSegmentIndex: segmentIndex
    });
  }

  toggleAddSegmentModal = () => {
    this.setState({ 
      showAddSegmentModal: !this.state.showAddSegmentModal,
      submitSegmentState: "idle"
    });
  }

  toggleAddStudentModal = () => {
    this.setState({
      showAddStudentModal: !this.state.showAddStudentModal
    })
  }


  render() {
    return (
      <ChapterBarPresentational 
        submitChapterState={this.state.submitChapterState}
        formOnChange={this.formOnChange}
        toggleSegmentPublish={this.props.toggleSegmentPublish}
        selectedChapter={this.props.selectedChapter}
        selectChapter={this.props.selectChapter}
        selectedChapterIndex={this.props.selectedChapterIndex}
        chapters={this.props.chapters}
        segments={this.props.segments}
        deleteSegment={this.props.deleteSegment}
        title={this.state.title}
        app={this.props.app}
        type={this.state.type}
        error={this.state.error}
        description={this.state.description}
        submitNewChapter={this.submitNewChapter}
        submitSegmentState={this.state.submitSegmentState}
        submitNewSegment={this.submitNewSegment}
        toggleAddLessonModal={this.toggleAddLessonModal}
        showAddLessonModal={this.state.showAddLessonModal}
        showAddSegmentModal={this.state.showAddSegmentModal}
        showAddStudentModal={this.state.showAddStudentModal}
        toggleAddSegmentModal={this.toggleAddSegmentModal}
        toggleAddStudentModal={this.toggleAddStudentModal}
      />
    )
  }
}

export default ChapterBarContainer;