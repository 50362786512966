import React from "react";
import { Button } from "./ButtonWithState.style";
import styled, { keyframes } from 'styled-components'

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`

export const Loader = styled.div`
  border: 0.2em solid rgba(0, 0, 0, 0.1);
  border-top: 0.2em solid white;
  border-radius: 50%;
  width: 1.4rem;
  height: 1.4rem;
  animation: ${spin} 0.6s linear infinite;
`;

const ButtonWithState = props => {
  switch (props.state) {
    case "idle":
      return (
        <Button onClick={props.onClick} type="primary">
          {props.idleText || props.text}
        </Button>
      );
    case "resolved":
      return (
        <Button
          onClick={props.onClick}
          type="primary"
          style={{ background: "green", borderColor: "green" }}
        >
          {props.successText || props.text}
        </Button>
      );
    case "rejected":
      return (
        <Button
          onClick={props.onClick}
          type="danger"
          style={{ background: "#ee2a2e", borderColor: "#ee2a2e" }}
        >
          {props.failureText || props.text}
        </Button>
      );
    case "pending":
      return (
        <Button
          onClick={props.onClick}
          color="info"
          style={{ background: "#17a2b8", borderColor: "#17a2b8" }}
        >
          <Loader />
        </Button>
      );
    case "waiting":
      return (
        <Button
          onClick={props.onClick}
          color="warning"
          style={{ background: "#e0a800", borderColor: "#e0a800" }}
        >
          {props.idleText || props.text}
        </Button>
      );
    default:
      return (
        <Button onClick={props.onClick} type="primary">
          {props.idleText || props.text}
        </Button>
      );
  }
}

export default ButtonWithState;