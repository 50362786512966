import styled from "styled-components";

export const ParticleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* max-width: 720px; */
  padding: 30px;
  background-color: #f5f6f8;
  /* background-image: linear-gradient( 135deg, #69FF97 10%, #00E4FF 100%); */
  overflow: auto;
  @media (max-width: 768px) {
    padding: 0px;
  }
`;

export const ParticleHeading = styled.h1`
  
`;

export const ParticleDescription = styled.h2`

`

export const TextColorHeading = styled.div`
  font-size: 18px;
  font-family: Ubuntu, sans-serif;
`