import React from "react";
import axios from "axios";
import { Redirect } from "react-router-dom"

class RedirectToWorkspace extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appId: ""
    }
  }

  componentDidMount() {
    axios
      .get('/app/get-default-app')
      .then(resp => {
        this.setState({appId: resp.data.app._id})
      })
      .catch(err => console.error(err.response))
  }

  render() {
    if(this.state.appId) {
      return (
        <Redirect to={{pathname: `workspace/${this.state.appId}`}} />
      )
    }
    return (
      <div>
        Loading
      </div>
    )
  }
}

export default RedirectToWorkspace;