import React from 'react';
import axios from 'axios';
import { withRouter } from "react-router";

import ParticleBuilderPresentational from "../Presentationals/ParticleBuilderPresentational"
import cloneDeep from "lodash.clonedeep"
import { Spin } from "antd"
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;
class ParticleBuilder extends React.Component {
  constructor(props) {
    super();
    this.state = {
      particles: [],
      dropdownOpen: false,
      editChapterDetails: false,
      selectedChapterId: "",
      patchButtonColor: "primary",
      networkState: "idle",
      isPublished: false,
      googleSheetUrl: "",
      title: "",
      description: "",
      selectedChapterId: "",
      textColor: "#4fb0ae",
      initialLoading: "pending"
    }
  }

  toggleChapterPublish = () => {
    let currentChapterStatus = this.state.isPublished
    this.setState({
      isPublished: !currentChapterStatus
    })
    axios
    .patch(`/chapter/${this.props.match.params.chapterId}`, {
      isPublished: !currentChapterStatus
    })
    .then(resp => {
      this.setState({
        // patchButtonColor: "resolved"
      })
    })
    .catch(err => {
      this.setState({
        isPublished: currentChapterStatus
      })
    })

  }

  patchChapterDetails = () => {
    this.setState({
      patchButtonColor: 'pending'
    })
    axios
      .patch(`/chapter/${this.props.match.params.chapterId}`, {
        title: this.state.title,
        description: this.state.description,
        textColor: this.state.textColor
      })
      .then(resp => {
        this.setState({
          patchButtonColor: "resolved"
        })
      })
      .catch(err => {
        this.setState({
          patchButtonColor: "rejected"
        })
      })
  }

  onChangeChapterDetails = (key, value) => {
    if(this.state.setTimeOutId){
      clearTimeout(this.state.setTimeOutId)
    }
    this.setState({
      [key]: value,
      patchButtonColor: "waiting",
      networkState: 'waiting',
      setTimeOutId:setTimeout(() => {this.patchChapterDetails()}, 1000)
     })
  }

  onChangeChapterDetailsAntd = (name, value) => {
    // this.setState({
    //   [name]: value,
    //   patchButtonColor: "primary"
    // })
  }

  changeGoogleSheetUrl = (e) => {
    this.setState({
      googleSheetUrl: e.target.value
    })
  }

  toggleDropdown = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen })
  }

  componentDidMount() {
    if(this.props.selectedChapter){
      this.getLessonData(this.props.match.params.chapterId)
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(this.props.match.params.chapterId !== prevProps.match?.params?.chapterId){
      this.getLessonData(this.props.match.params.chapterId)
    }
  }

  toggleEditChapterDetails = () => {
    this.setState({ editChapterDetails: !this.state.editChapterDetails })
  }

  getLessonData = (chapterId) => {
    axios
      .get(`/chapter/${chapterId}`)
      .then(resp => {
        this.setState({
          particles: resp.data.particles,
          isPublished: resp.data.isPublished,
          title: resp.data.title,
          description: resp.data.description,
          chapterType: resp.data.type,
          textColor: resp.data.textColor ? resp.data.textColor : "#4fb0ae",
          initialLoading: 'completed'
        })
      })
      .catch(err => {
        console.error(err)
      })
  }

  addNewParticle = (type) => {
    let nextParticleRank = 100;
    if(this.state.particles && this.state.particles.length && this.state.particles[this.state.particles.length - 1]){
      nextParticleRank = this.state.particles[this.state.particles.length - 1].rank + 100;
    }
    axios.post('/particle', {
      type,
      chapterId: this.props.match.params.chapterId,
      rank: nextParticleRank
    })
    .then(resp => {
      this.getLessonData(this.props.match.params.chapterId)
    })
    .catch(err => {
      console.error(err)
    })
  }


  deleteParticle = (id) => {
    axios
    .delete(`/particle/${id}`)
    .then(resp => {
      this.getLessonData(this.props.match.params.chapterId)
    })
    .catch(err => {
      this.setState({
        message: "not deleted something happened"
      })
    })
  }
  
  importFromGoogleSheet = () => {
    axios.post(`/particle/import-from-google`, {
      chapterId: this.props.match.params.chapterId,
      url: this.state.googleSheetUrl
    })
    .then(resp => {
      this.getLessonData(this.props.match.params.chapterId)
    })
    .catch(err => {
      console.error("errors",err.response)
    })
  }

  getRank = (arrayOfParticles, indexLeft, indexRight) => {
    let endIndexNeighbourLeft, endIndexNeighbourRight;
    endIndexNeighbourLeft = arrayOfParticles[indexLeft];
    endIndexNeighbourRight = arrayOfParticles[indexRight];
    let newRank = 0;
    if(!endIndexNeighbourLeft){
      newRank = endIndexNeighbourRight.rank/2;
    } else if(!endIndexNeighbourRight){
      newRank = endIndexNeighbourLeft.rank+100;
    } else {
      newRank = (endIndexNeighbourLeft.rank + endIndexNeighbourRight.rank)/2;
    }
    return newRank;
  }

  reorderParticles = (startIndex, endIndex) => {
    let particlesOld = cloneDeep(this.state.particles);
    let particlesBackup = cloneDeep(this.state.particles);
    const [movedParticle] = particlesOld.splice(startIndex, 1);
    let movedParticleRank = 0;
    if(startIndex > endIndex){
      movedParticleRank = this.getRank(particlesBackup, endIndex-1, endIndex)
    } else {
      movedParticleRank = this.getRank(particlesBackup, endIndex, endIndex+1)
    }
    movedParticle.rank = movedParticleRank;
    particlesOld.splice(endIndex, 0, movedParticle);
    // axios
    //   .patch(`/chapter/${this.props.match.params.chapterId}`, {

    //   })

    this.setState({
      particles: particlesOld,
      networkState: "pending"
    })


    axios.patch(`/particle/${movedParticle._id}`, {
      rank: movedParticle.rank
    })
    .then(resp => {
      this.setState({
        networkState: "success"
      })
    })
    .catch((err) => {
      this.setState({
        networkState: "fail",
        particles: particlesBackup
      })
    })
  }


  render(){
    if(!this.props.selectedChapter){
      return null;
    }

    if(this.state.initialLoading === 'pending'){
      return (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1
        }}>
          <Spin indicator={antIcon} />
        </div>
      )
    }

    return (
      <ParticleBuilderPresentational 
        importFromGoogleSheet={this.importFromGoogleSheet}
        toggleEditChapterDetails={this.toggleEditChapterDetails}
        addNewParticle={this.addNewParticle}
        reorderParticles={this.reorderParticles}
        deleteParticle={this.deleteParticle}
        deleteChapter={this.props.deleteChapter}
        particles={this.state.particles}
        toggleChapterPublish={this.toggleChapterPublish}
        isPublished={this.state.isPublished}
        editChapterDetails={this.state.editChapterDetails}
        getLessonData={() => this.getLessonData(this.props.match.params.chapterId)}
        changeGoogleSheetUrl={this.changeGoogleSheetUrl}
        dropdownOpen={this.state.dropdownOpen}
        toggleDropdown={this.toggleDropdown}
        chapterType={this.state.chapterType}
        selectedChapter={this.props.selectedChapter}
        selectedChapterId={this.props.match.params.chapterId}
        title={this.state.title}
        onChangeChapterDetailsAntd={this.onChangeChapterDetailsAntd}
        patchChapterDetails={this.patchChapterDetails}
        description={this.state.description}
        patchButtonColor={this.state.patchButtonColor}
        onChangeChapterDetails={this.onChangeChapterDetails}
        textColor={this.state.textColor}
      />
    )
  }
}

export default withRouter(ParticleBuilder);