import React from 'react';
import {
  withRouter
} from "react-router-dom";
import Widget from "./Widget"

let BASE_URL = "https://api.makethemstay.com";
if(process.env.NODE_ENV === "development"){
  BASE_URL = "http://localhost:3001";
}

const Preview = props => {
  return (
    <div>
      <Widget id={props.match.params.chapterId} baseUrl={BASE_URL}/>
    </div>
  );
}

export default withRouter(Preview);