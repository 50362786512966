import React from 'react';
import { Col, Form, FormGroup } from 'reactstrap';

import { Button } from "antd"
import TextareaAutosize from 'react-textarea-autosize';
 
import * as Styles from "./NotesBuilderStyled"
import * as Formed from "../../../components/StyledItems/Form"
import ButtonWithState from "../../../components/ButtonWithState"

const NotesBuilderPresentational = props => {
  return (
    <Styles.NotesWrapper>
      <Formed.ParticleHeading>Note</Formed.ParticleHeading>
      {/* <Styles.ParticleId>{props.particle._id}</Styles.ParticleId> */}
      <Form>
        <FormGroup row>
          {/* <Label for="title" sm={2}>Title</Label> */}
          <Col sm={12}>
            <Formed.H2Input 
              type="text" 
              name="title" 
              value={props.title}
              onChange={props.onChangeNotes}
              placeholder="note title" />
          </Col>
          <Col sm={12}>
            <TextareaAutosize 
              style={{
                border: 0,
                outline: 0,
                fontSize: '18px',
                width: '100%',
                backgroundColor: 'transparent',
                fontFamily: 'Ubuntu, sans-serif',
              }}
              type="textarea" 
              name="text" 
              rows={10}
              value={props.text}
              onChange={props.onChangeNotes}
              placeholder="note content"
            />
            {/* <Formed.MultilineInput 
              type="textarea" 
              name="text" 
              rows={10}
              id="text" 
              value={props.text}
              onChange={props.onChangeNotes}
              placeholder="note content" /> */}
          </Col>
        </FormGroup>
      </Form>
      <Styles.BottomButtonWrapper>
        <FormGroup style={{paddingRight: "15px"}}>
          <Button danger type="primary" onClick={props.deleteNote}>
            Delete Note
          </Button>
        </FormGroup>
        <FormGroup>
          <ButtonWithState 
            color="primary" 
            text="Save"
            successText="saved"
            failureText="failed"
            state={props.networkState}
            onClick={props.saveNotes}/>
        </FormGroup>
        {/* <div>{props.message}</div> */}
      </Styles.BottomButtonWrapper>
    </Styles.NotesWrapper>
  )
}

export default NotesBuilderPresentational;