import styled from "styled-components"

export const HeadingInput = styled.input`
  border: 1px solid transparent;
  outline: 0;
  font-size: 32px;
  font-family: 'Ubuntu', sans-serif;
  max-width: 94vw;
  font-weight: 700;
  /* padding: 0px 20px; */
  background-color: transparent;
  &::placeholder {
    color: #a7a7a7;
  };
  &:hover {
    border: 1px solid #c3c3c3;
    border-radius: 7px;
  };
  &:focus {
    border: 1px solid #c3c3c3;
    border-radius: 7px;
  }
`;

export const SubHeadingInput = styled.input`
  border: 0;
  outline: 0;
  font-size: 24px;
  width: 100%;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  background-color: transparent;
  &::placeholder {
    color: #a7a7a7;
  }
`;

export const SubHeadingInputMultiline = styled.textarea`
  border: 0;
  outline: 0;
  font-size: 24px;
  width: 100%;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  background-color: transparent;
  &::placeholder {
    color: #a7a7a7;
  }
`;

export const OrdinaryInput = styled.input`
  border: 0;
  outline: 0;
  font-size: 18px;
  width: 100%;
  background-color: transparent;
  font-family: 'Ubuntu', sans-serif;
`;

export const H2Input = styled.input`
  border: 0;
  outline: 0;
  font-size: 22px;
  width: 100%;
  background-color: transparent;
  font-family: 'Ubuntu', sans-serif;
`

export const MultilineInput = styled.textarea`
  border: 0;
  outline: 0;
  font-size: 18px;
  width: 100%;
  background-color: transparent;
  font-family: 'Ubuntu', sans-serif;
`

export const TitleInput = styled.input`
  border: 0;
  outline: 0;
  font-size: 18px;
  width: 100%;
  font-size: 24px;
  background-color: transparent;
`

export const ParticleHeading =styled.h3`
  color: #34323f;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
`;
