import React from 'react';
import axios from "axios";
import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";


import "./App.css";
import 'antd/dist/antd.css';
import "./index.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './screens/Home';
import Workspace from './screens/Workspace';
import FormBuilder from './screens/FormBuilder'
import Auth from './screens/Auth';
import ContentEditorScreen from './screens/ContentEditor';
import ItemStructure from "./screens/ItemStructure"
import Item from "./screens/Item";
import ProtectedRoute from "./components/ProtectedRoute"
import Responses from "./screens/Responses"
import Form from "./screens/Form"
import RedirectToWorkspace from "./screens/RedirectToWorkspace";
import ResetPassword from "./screens/ResetPassword";

if(process.env.NODE_ENV === "production"){
  axios.defaults.baseURL = 'https://api.makethemstay.com'
} else {
  axios.defaults.baseURL = "http://localhost:3001"
}

function App() {

  return (
    <Router>
      <Switch>
        <Route path="/auth">
          <Auth />
        </Route>
        <ProtectedRoute path="/app/:appId" component={ContentEditorScreen}/>
        <ProtectedRoute path="/workspace/:appId" component={Workspace}/>
        <ProtectedRoute path="/form/:chapterId" component={Form} />
        {/* <ProtectedRoute path="/form/:chapterId/responses" component={Responses} />
        <ProtectedRoute path="/form/:chapterId" component={FormBuilder} /> */}
        {/* <ProtectedRoute path="/" component={Home} exact/> */}
        <ProtectedRoute path="/" component={RedirectToWorkspace} exact/>
        <ProtectedRoute path="/item-structure" component={ItemStructure} />
        <ProtectedRoute path="/item" component={Item} />
        <Route path="/reset-password/:token" component={ResetPassword} />
      </Switch>
  </Router>
  )
}

export default App;
