import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #f5f6f8;
  overflow: auto;
  flex: 1;
  flex-shrink: 1;
`;

export const ContentWrapper = styled.div`
  max-width: 1025px;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ResponsePageWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  max-width: 1025px;
  overflow: auto;
`

export const DateWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px;
`

export const Sidebar = styled.div`
  background-color: #f5f6f8;
  overflow: auto;
  width: 300px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const SelectorWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px;
  @media (min-width: 768px) {
    display: none;
  }
`

export const SummaryList = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 700px;
`

export const Date = styled.div`
  font-size: 14px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  padding: 15px;
  border-bottom: 1px solid black;
  background-color: ${props => props.isSelected ? '#cccccc' : 'transparent' };
`;

export const NoResponses = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
`

export const ResponseWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  align-self: flex-start;
  overflow: auto;
  padding: 30px;
  flex-grow: 1;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px, rgb(0 0 0 / 4%) 0px 8px 14px, rgb(0 0 0 / 2%) 0px 12px 16px;
  max-width: 690px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ResponsePadding = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 0px 15px;
  margin-bottom: 15px;
`

export const IndividualResponse = styled.div`
  padding: 25px;
`

export const Question = styled.div`
  font-size: 14px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
`

export const Answer = styled.div`
  font-size: 18px;
  font-family: 'Ubuntu', sans-serif;
`;

export const Navbar = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  margin: auto;
`

export const Heading = styled.div`
  font-family: Ubuntu, sans-serif;
`

export const IndividualLink = styled.div`
  padding: 10px;
`

export const LinkWrapper = styled.div`
  display: flex;
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
`

export const ProgressContainer = styled.div`
  height: 20px;
  width: 100%;
  background-color: rgb(240, 240, 240);
  border-radius: 2px;
  margin: 40px 0px;
  font-family: Ubuntu, sans-serif;
`

export const ProgressFiller = styled.div`
  height: 100%;
  width: ${props => `${props.completed}%`};
  background-color: #3a97b8;
  border-radius: inherit;
  text-align: center;
  font-family: Ubuntu, sans-serif;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
`;

export const ProgressLabel = styled.div`
  padding: 5px;
  color: white;
  font-family: Ubuntu, sans-serif;
`;

export const Ubuntu = styled.div`
  font-family: Ubuntu, sans-serif;
  color: black;
`

export const InsightTitle = styled.div`
  font-family: Ubuntu, sans-serif;
  font-size: 1rem;
`

export const InsightCount = styled.div`
  font-family: Ubuntu, sans-serif;
  font-size: 2.6rem;
`

export const InsightWrapper = styled.div`
  padding: 30px;

`

export const Insights = styled.div`
  display: flex;
`